import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { retrunLanguage } from "../../../../utils/languageSelect";

import { paths } from "../../../../routes/paths";

import { calculateDistance } from "../../../../utils/checkNearestDistance";

import "./style.css";
import {
  setPickupBranch,
  setPickupBranchID,
  setPickupBranchName,
} from "../../../../store/reducers/resturantsSlice";

const PickupBranchesDetails = ({ branchesData }) => {
  const { state } = useLocation();

  const defaultLocation = JSON.parse(localStorage.getItem("location"));
  const selected = JSON.parse(localStorage.getItem("selected"));

  const distanceFromLocation = (branchLat, branchlng) => {
    let lat;
    let lng;

    if (selected === null) {
      lat = defaultLocation.lat;
      lng = defaultLocation.lng;
    } else {
      lat = selected.lat;
      lng = selected.lng;
    }

    if (lat && lng) {
      const distance = calculateDistance(lat, lng, +branchLat, +branchlng);

      return distance;
    }

    return Infinity;
  };

  const sortedBranches = branchesData
    .filter((branch) => branch?.is_display === "1")
    .map((branch) => {
      const distanceFromBranch = distanceFromLocation(
        branch.latitude,
        branch.longitude
      );
      return { ...branch, distanceFromBranch };
    })
    .sort((a, b) => a.distanceFromBranch - b.distanceFromBranch);

  return (
    <div>
      {sortedBranches.map((branch) => (
        <RestaurantCard
          key={branch.id}
          {...branch}
          distanceFromBranch={branch.distanceFromBranch}
          filterSelected={state}
        />
      ))}
    </div>
  );
};

const RestaurantCard = ({
  weekdayHours,
  weekendHours,
  branch_hours,
  name_en,
  name_ar,
  restaurant_logo,
  restaurant_id,
  id,
  distanceFromBranch,
  filterSelected,
}) => {
  const { i18n, t } = useTranslation("pickup");

  const token = localStorage.getItem("token");

  const cart = useSelector((state) => state.cart.cart);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openStatus, setOpenStatus] = useState("");

  useEffect(() => {
    const date = new Date();
    const options = { weekday: "long" };
    const dayName = date.toLocaleDateString("en-US", options);

    const findStatus = branch_hours?.filter(
      (branch) => branch.week_day === dayName
    );

    setOpenStatus(findStatus[0]?.openingStatus);
  }, []);

  const branchSelectHandler = (orderTime) => {
    const branch_name = retrunLanguage(i18n) ? name_ar : name_en;

    const obj = {
      branchName: branch_name,
      branchId: id,
    };

    dispatch(setPickupBranch(obj));

    if (cart.length > 0 && restaurant_id === cart[0]?.restaurant_id) {
      localStorage.setItem("branchName", branch_name);
      dispatch(setPickupBranchID(id));
      dispatch(setPickupBranchName(branch_name));
    }

    // localStorage.setItem("branchName", branch_name);
    navigate(paths.menu + `/restaurant/${restaurant_id}/pickup`, {
      state: {
        orderTime: orderTime,
      },
    });
  };

  return (
    <div className="pickup-branches-container">
      <div className="pickup-branch-info">
        <div className="restaurant-details">
          <div className="pickup-restaurant-logo">
            <img src={restaurant_logo} />
          </div>

          <div className="pickup-branch-timings">
            <div className="pickup-branch-details">
              <strong>
                {t("branchHours.weekdays", { ns: "translation" })}:{" "}
              </strong>

              <span> {weekdayHours}</span>
            </div>

            <div className="pickup-branch-details">
              <strong>
                {t("branchHours.weekends", { ns: "translation" })}:{" "}
              </strong>

              <span> {weekendHours}</span>
            </div>
          </div>
        </div>

        <div className="branch-details">
          <h1>{retrunLanguage(i18n) ? name_ar : name_en}</h1>

          <div
            className={`pickup-branch-status-section ${
              retrunLanguage(i18n)
                ? "pickup-branch-status-ar"
                : "pickup-branch-status-en"
            }`}
          >
            <div className="pickup-branch-status-container">
              <div
                className={`branch-status-tag ${openStatus?.toLowerCase()} ${
                  openStatus === "Close"
                    ? "branch-closed"
                    : openStatus === "Closing Soon"
                    ? "branch-closing-soon"
                    : openStatus === "Open"
                    ? "branch-open-now"
                    : ""
                }`}
              >
                {openStatus}
              </div>
            </div>
          </div>
        </div>

        <div className="pickup-order">
          <span className={retrunLanguage(i18n) && "ar-distance"}>
            {t("distance")}{" "}
            <strong>{`${distanceFromBranch.toFixed(1)} ${t("km")}`}</strong>
          </span>

          <div className="pickup-button">
            {filterSelected === "dinein" ? (
              <button
                className="fill"
                disabled={openStatus !== "Open" ? true : false}
                onClick={() => {
                  if (token) {
                    navigate(
                      paths.menu + `/restaurant/${restaurant_id}/dinein`
                    );
                  } else {
                    navigate(paths.phone_verification_index, {
                      state: {
                        filterSelectedNow: "dinein",
                        restaurant_id: restaurant_id,
                      },
                    });
                  }
                }}
                style={{ opacity: openStatus !== "Open" ? 0.3 : 1 }}
              >
                {t("queueNow", { ns: "dinein" })}
              </button>
            ) : openStatus === "Close" ? (
              <button
                className="outline"
                onClick={() => branchSelectHandler("scheduled")}
              >
                {t("scheduleOrder")}
              </button>
            ) : (
              <button
                onClick={() => branchSelectHandler("asap")}
                className="fill"
              >
                {t("orderFromHere")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickupBranchesDetails;
