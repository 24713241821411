import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";

import { retrunLanguage } from "../../utils/languageSelect";

import "./style.css";

const DatePickerDialog = (props) => {
  const { t, i18n } = useTranslation("dialog");

  const {
    dineinDateTimeSelector,
    handleClose,
    isPickerType,
    setIsPickerType,
    setDineinDateTimeSelector,
    setDate,
    setTime,
  } = props;

  const [selectDate, setSelectDate] = useState(null);
  const [selectTime, setSelectTime] = useState(null);

  return (
    <Dialog
      open={dineinDateTimeSelector}
      onClose={() => handleClose()}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: "0",
          bottom: "0",
          position: "fixed",
          width: "375px",
          left: retrunLanguage(i18n) ? "unset" : "0",
          right: retrunLanguage(i18n) ? "0" : "unset",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",

          "@media (max-width: 500px)": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle className="close-icon" onClick={() => handleClose()}>
        <img src="/assets/img/close.svg" />
      </DialogTitle>

      <DialogContent
        sx={{
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
        className="date-time-picker-dialog"
      >
        <Box className={`date-time-heading active-heading`}>
          <Button
            className={isPickerType === "date" ? `${isPickerType}-active` : ""}
            onClick={() => setIsPickerType("date")}
            disabled={isPickerType === "time"}
          >
            {t("date", { ns: "dinein" })}
          </Button>

          <Button
            className={isPickerType === "time" ? `${isPickerType}-active` : ""}
            onClick={() => setIsPickerType("time")}
            disabled={isPickerType === "date"}
          >
            {t("time", { ns: "dinein" })}
          </Button>
        </Box>

        {isPickerType === "date" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              disablePast={true}
              onChange={(date) => setSelectDate(date)}
            />
          </LocalizationProvider>
        )}

        {isPickerType === "time" && (
          <>
            <Box className="time-header">
              <Typography variant="h4">{t("hour")}</Typography>
              <Typography variant="h4">{t("minute")}</Typography>
              <Box sx={{ width: "79px", marginRight: "12px" }} />
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MultiSectionDigitalClock
                timeSteps={{ minutes: 10 }}
                minutesStep={10}
                onChange={(date) => setSelectTime(date)}
              />
            </LocalizationProvider>
          </>
        )}

        <Box sx={{ padding: "0px 28px", marginTop: "5px" }}>
          <Button
            className="date-time-confirm"
            onClick={() => {
              if (selectDate) {
                setDate(selectDate);
              }

              if (selectTime) {
                setTime(selectTime);
              }

              setDineinDateTimeSelector(false);
            }}
          >
            {t("confirm", { ns: "common" })}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DatePickerDialog;
