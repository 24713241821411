import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import Select from "react-select";
import { selectStyles } from "../selectStyles";
import "./style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrunLanguage } from "../../../../utils/languageSelect";
import usePollingQueueStatus from "../../../../api/restaurant/dineIn/dineinQueuePolling";
import getUserQueueInfo from "../../../../api/restaurant/dineIn/getUserQueueInfo";
import { setDineinQueueCustomerInfo } from "../../../../store/reducers/resturantsSlice";
import DineinChangeBranch from "../../../dialog/dineinChangeBranch";

const queueFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "First name must contain only alphabets")
    .required("First name is required"),
  lastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Last name must contain only alphabets")
    .required("Last name is required"),
  mobile_no: Yup.string()
    .matches(/^\d{8}$/, "Mobile number must be exactly 8 digits")
    .required("Mobile number is required"),
  mobile_country_code: Yup.string().required("Country code is required"),
  seatingArea: Yup.string().required("Seating area is required"),
  guests: Yup.string().required("Guests selection is required"),
});

const QueueCard = ({
  queue,
  setStartEditing,
  startEditing,
  setCancelDineine,
  isLoggedIn,
  userQueueInfo,
  handleJoinQueue,
  restaurant_id,
  isUserInQueue,
  handleChangeBranch,
  handleUpdateQueueDetails,
}) => {
  const { t, i18n } = useTranslation("dinein");
  const [isAlreadyQueue, setIsAlreadyQueue] = useState(false);
  const [queueData, setQueueData] = useState([]);
  const [changeBranch, setChangeBranch] = useState(false);

  const queueCurrentStatus = usePollingQueueStatus(
    queue?.branch_id,
    restaurant_id
  )?.data;

  const seatingAreaOptions = [
    { value: "any", label: "Any" },
    { value: "inside", label: "Inside" },
    { value: "outside", label: "Outside" },
  ];

  const guestsOptions = [
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "04", label: "04" },
    { value: "06", label: "06" },
  ];

  const countryCodeOptions = [{ value: "965", label: "+965" }];
  const phoneNumber = `${queue.phone_country_code}${queue.phone_no}`;
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: userQueueInfo?.first_name || "",
      lastName: userQueueInfo?.last_name || "",
      mobile_no: userQueueInfo?.mobile_no || "",
      mobile_country_code: userQueueInfo?.mobile_country_code || "965",
      seatingArea: userQueueInfo?.sitting_area || "",
      guests: userQueueInfo?.guest_allowed || "",
      action: "",
    },
    validationSchema: queueFormSchema,
    onSubmit: (values) => {
      const { action, ...rest } = values;

      const queueInfo = {
        ...rest,
        restaurantId: restaurant_id,
        branch_id: queue?.branch_id,
      };
      const queueId = userQueueInfo?.queue_id;

      if (action === "changeBranch") {
        // handle changeBranch action
        setChangeBranch(true);
      } else if (action === "confirm") {
        handleChangeBranch(queueInfo, queue?.branch_name_en);
        setChangeBranch(false);
        // handle joinQueue action
      } else if (action === "joinQueue") {
        handleJoinQueue(queueInfo, queue?.branch_name_en);
        // handle joinQueue action
      } else if (action === "save") {
        // handle save action
        handleUpdateQueueDetails(queueInfo, queueId, queue?.branch_name_en);
      }

      formik.setFieldValue("action", "");
    },
  });

  const operationalHours = () => {
    const {
      weekdays_hours_from,
      weekdays_hours_to,
      weekend_hours_from,
      weekend_hours_to,
    } = queue;
    // Get the current day of the week
    const today = new Date().getDay();

    // Determine if today is a weekend or weekday
    const isWeekend = today === 5 || today === 6; // Friday (5) or Saturday (6)

    // Return the operational hours based on the day
    if (isWeekend) {
      return `${weekend_hours_from} - ${weekend_hours_to}`;
    } else {
      return `${weekdays_hours_from} - ${weekdays_hours_to}`;
    }
  };

  const redirectLocation = () => {
    const latlng = { lat: queue.latitude, lng: queue.longitude };
    navigate("/location", { state: { latlng } });
  };

  const generateGuestsOptions = (guestAllowed) => {
    const options = [];
    const limit = parseInt(guestAllowed);

    for (let i = 1; i <= limit; i += 1) {
      const value = i.toString().padStart(2, "0");
      options.push({ value, label: value });
    }

    return options;
  };

  useEffect(() => {
    if (userQueueInfo !== null) {
      const queueStatusObject = {
        branch_waiting_time: userQueueInfo?.waiting_time.split("Minutes")[0],
        branch_queue_no: userQueueInfo?.queue_no,
      };
      setQueueData([queueStatusObject]);
      setIsAlreadyQueue(true);
    } else {
      setIsAlreadyQueue(false);
      setQueueData([]);
    }
  }, [userQueueInfo]);

  useEffect(() => {
    setQueueData(queueCurrentStatus);
  }, [queueCurrentStatus]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className="queue-card-box">
        <Box className="queue-image">
          {/* <img src={queue.dinein_image} alt={queue.restaurant_name} /> */}
          <img src={queue?.dinein_image} />
        </Box>

        <Box className="queue-card-detail-section">
          <Box className="queue-card-header">
            <Box>
              <Typography variant="h4">
                {retrunLanguage(i18n)
                  ? `${queue.restaurant_name_ar} - ${queue.branch_name_ar}`
                  : `${queue.restaurant_name_en} - ${queue.branch_name_en}`}
              </Typography>
              <Typography>
                {t("openingHours")} {operationalHours()}
              </Typography>
            </Box>

            <Box className="queue-card-leftside">
              <div
                className={`branch-status-tag ${queue.opening_status?.toLowerCase()} ${
                  queue.opening_status === "close"
                    ? "branch-closed"
                    : queue.opening_status === "Closing Soon"
                    ? "branch-closing-soon"
                    : queue.opening_status === "open"
                    ? "branch-open-now"
                    : ""
                }`}
              >
                {queue.opening_status}
              </div>
              <IconButton className="queue-location">
                <img
                  src="/assets/dinein/Location.svg"
                  onClick={redirectLocation}
                  alt="Location"
                />
              </IconButton>
              <a
                href={`tel:+${phoneNumber}`}
                style={{ textDecoration: "none" }}
              >
                <IconButton>
                  <img src="/assets/dinein/Call.svg" alt="Call" />
                </IconButton>
              </a>
            </Box>
          </Box>

          <Box className="queue-status-details">
            <Box className="wait-status">
              <Typography variant="h3">{t("currentQueue")}</Typography>
              <Typography variant="h1">
                {queueData?.length === 0
                  ? "0"
                  : queueData?.[0]?.branch_queue_no}
              </Typography>
            </Box>

            <Box
              className={`wait-status minutes-waiting ${
                queueData?.length === 0 ? "table-available" : ""
              }`}
              sx={{
                backgroundColor:
                  queueData?.length === 0 ? "#026754" : "#f0f6f5",
              }}
            >
              {queueData?.length !== 0 && (
                <Typography variant="h1">
                  {queueData?.[0]?.branch_waiting_time.split("Minutes")}
                </Typography>
              )}
              <Typography
                variant="h3"
                sx={{
                  color: queueData?.length === 0 ? "#FFFFFF" : "#959fa8",
                }}
              >
                {queueData?.length === 0 ? t("readyToServe") : t("waitingTime")}
              </Typography>

              <div className="queue-clock">
                <IconButton>
                  <img src="/assets/dinein/Clock.svg" alt="Clock" />
                </IconButton>
              </div>
            </Box>
          </Box>

          <Box className="queue-guests-info">
            <Box className="queue-select-area">
              <Typography variant="h3">{t("seatingArea")}</Typography>
              <Select
                placeholder={t("seatingArea")}
                value={seatingAreaOptions.find(
                  (option) => option.value === formik.values.seatingArea
                )}
                onChange={(option) =>
                  formik.setFieldValue("seatingArea", option.value)
                }
                options={seatingAreaOptions}
                styles={selectStyles()}
                isDisabled={!startEditing && isAlreadyQueue}
                defaultValue={seatingAreaOptions[0]}
              />
            </Box>

            <Box className="queue-select-area queue-guests">
              <Typography variant="h3">{t("guests")}</Typography>
              <Select
                placeholder={t("guests")}
                value={guestsOptions.find(
                  (option) => option.value === formik.values.guests
                )}
                onChange={(option) =>
                  formik.setFieldValue("guests", option.value)
                }
                options={generateGuestsOptions(queue.total_guest_allowed)}
                styles={selectStyles()}
                isDisabled={!startEditing && isAlreadyQueue}
                defaultValue={guestsOptions[0]}
              />
            </Box>
          </Box>

          {isLoggedIn && (
            <Grid container spacing={1.5} className="queue-form-container">
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "36px !important",
                      marginBottom: "0px !important",
                      font: "normal normal bold 12px Titillium Web",
                      backgroundColor: "#FAFAFA",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#959fa8",
                      font: "normal normal bold 12px/28px Titillium Web",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C0CDD7",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  variant="outlined"
                  placeholder={t("form.firstName", { ns: "translation" })}
                  name="firstName"
                  disabled={!startEditing && isAlreadyQueue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={t("form.lastName", { ns: "translation" })}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "36px !important",
                      marginBottom: "0px !important",
                      font: "normal normal bold 12px Titillium Web",
                      backgroundColor: "#FAFAFA",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C0CDD7",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#959fa8",
                      font: "normal normal bold 12px/28px Titillium Web",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  name="lastName"
                  disabled={!startEditing && isAlreadyQueue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>

              <Grid item xs={3}>
                <Select
                  placeholder="+965"
                  value={countryCodeOptions.find(
                    (option) =>
                      option.value === formik.values.mobile_country_code
                  )}
                  onChange={(option) =>
                    formik.setFieldValue("mobile_country_code", option.value)
                  }
                  defaultValue={countryCodeOptions[0]}
                  options={countryCodeOptions}
                  styles={selectStyles()}
                  isDisabled={!startEditing && isAlreadyQueue}
                />
              </Grid>

              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  placeholder={t("phoneNo")}
                  sx={{
                    width:"171px",
                    marginLeft: "28px",
                    "& .MuiInputBase-root": {
                      height: "36px !important",
                      marginBottom: "0px !important",
                      font: "normal normal bold 12px Titillium Web",
                      backgroundColor: "#FAFAFA",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C0CDD7",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#959fa8",
                      font: "normal normal bold 12px/28px Titillium Web",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  name="mobile_no"
                  disabled={!startEditing && isAlreadyQueue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile_no}
                  error={
                    formik.touched.mobile_no && Boolean(formik.errors.mobile_no)
                  }
                  helperText={
                    formik.touched.mobile_no && formik.errors.mobile_no
                  }
                  inputProps={{ maxLength: 8 }}
                />
              </Grid>
            </Grid>
          )}
        </Box>

        <Box className="queue-btn-container" sx={{ padding: "0px 15px" }}>
          {!startEditing ? (
            isAlreadyQueue ? (
              <>
                <Button
                  className="cancel"
                  onClick={() => setCancelDineine(true)}
                >
                  {t("cancel", { ns: "dialog" })}
                </Button>
                <Button className="edit" onClick={() => setStartEditing(true)}>
                  {t("edit", { ns: "common" })}
                </Button>
              </>
            ) : (
              <Button
                type="submit"
                className="join-queue"
                onClick={() =>
                  formik.setFieldValue(
                    "action",
                    isUserInQueue ? "changeBranch" : "joinQueue"
                  )
                }
              >
                {t(isUserInQueue ? "changeBranch" : "joinQueue")}
              </Button>
            )
          ) : isAlreadyQueue ? (
            <>
              <Button className="cancel" onClick={() => setStartEditing(false)}>
                {t("cancel", { ns: "dialog" })}
              </Button>
              <Button
                type="submit"
                className="save-edit"
                onClick={() => formik.setFieldValue("action", "save")}
              >
                {t("save", { ns: "dialog" })}
              </Button>
            </>
          ) : (
            <Button
              type="submit"
              className="join-queue"
              onClick={() =>
                formik.setFieldValue(
                  "action",
                  isUserInQueue ? "changeBranch" : "joinQueue"
                )
              }
            >
              {t(isUserInQueue ? "changeBranch" : "joinQueue")}
            </Button>
          )}
        </Box>
      </Box>

      <DineinChangeBranch
        changeBranch={changeBranch}
        setChangeBranch={setChangeBranch}
        handleConfirmChangeBranch={() => {
          formik.setFieldValue("action", "confirm");
          formik.handleSubmit();
        }}
      />
    </form>
  );
};

export default QueueCard;
