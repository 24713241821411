import React, { useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { paths } from "../../routes/paths";
import { useNavigate } from "react-router";
import styles from "./style.module.css";
import { useQuery } from "@tanstack/react-query";
import Navbar from "../../components/header";
import { useTranslation } from "react-i18next";
import ReservationQueueCard from "./reservationQueueCard";
import getReservationsAndQueue, {
  GET_RESERVATIONS_AND_QUEUE_QUERY_KEY,
} from "../../api/restaurant/dineIn/getReservationsAndQueue";
import cancelReservation from "../../api/restaurant/dineIn/cancelReservation";
import { useDispatch } from "react-redux";
import {
  removeQueueRequest,
  removeReservationRequest,
  setActiveButton,
  setIsEditReservation,
  setReservationInfoForEdit,
  setSelectedService,
} from "../../store/reducers/resturantsSlice";
import cancelQueue from "../../api/restaurant/dineIn/cancelQueue";
import DineinCancel from "../../components/dialog/dineinCancel";

const MyReservationScreen = () => {
  const { t } = useTranslation("order");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [category, setCategory] = useState([
    { type: "all", isActive: true },
    { type: "queue", isActive: false },
    { type: "reservation", isActive: false },
  ]);
  const [state, setState] = useState("all");
  const [allActive, setAllActive] = useState(true);
  const [queueActive, setQueueActive] = useState(false);
  const [reservationActive, setReservationActive] = useState(false);
  const [cancelDineine, setCancelDineine] = useState(false);
  const [cancelDineinInfo, setCancelDineinInfo] = useState();

  useEffect(() => {
    setCategory([
      { type: "all", isActive: allActive },
      { type: "queue", isActive: queueActive },
      { type: "reservation", isActive: reservationActive },
    ]);
  }, [allActive, reservationActive, queueActive]);

  //   const currentDate = new Date();
  //   const formattedDate = formatDateToCustomFormat(currentDate);

  function formatDateToCustomFormat(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  const noReservationsText = t("noReservations");

  const handleItems = (item, index) => {
    if (item.type === "all") {
      setAllActive(true);
      setQueueActive(false);
      setReservationActive(false);
    }

    if (item.type === "queue") {
      setQueueActive(true);
      setAllActive(false);
      setReservationActive(false);
    }

    if (item.type === "reservation") {
      setReservationActive(true);
      setAllActive(false);
      setQueueActive(false);
    }

    setState(item.type);
    const updateCategory = [...category];
    updateCategory.map((item) => (item.isActive = false));
    updateCategory[index].isActive = true;
    setCategory(updateCategory);
  };

  const {
    data: reservationAndQueueResponse,
    isLoading,
    isError,
  } = useQuery({
    queryFn: getReservationsAndQueue,
    queryKey: GET_RESERVATIONS_AND_QUEUE_QUERY_KEY,
    refetchInterval: false,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const populateSelectedDineinInfo = (item) => {
    setCancelDineinInfo(item);
    setCancelDineine(true);
  };

  const handleCancellation = () => {
    if (cancelDineinInfo?.dinein_type === "reservation") {
      cancelReservation(cancelDineinInfo?.reservation_id)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(
              removeReservationRequest(cancelDineinInfo?.reservation_id)
            );
            setCancelDineine(false);
          }
        })
        .catch((e) => console.error(e));
    } else {
      cancelQueue(cancelDineinInfo?.queue_id)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(removeQueueRequest(cancelDineinInfo?.queue_id));
            setCancelDineine(false);
          }
        })
        .catch((e) => console.error(e));
    }
  };
  const handleChangeReservationQueue = (reservationInfo) => {
    dispatch(setSelectedService("dinein"));
    dispatch(setIsEditReservation(true));
    dispatch(setActiveButton("reservation"));
    dispatch(setReservationInfoForEdit(reservationInfo));

    navigate(
      `${paths.menu}/restaurant/${reservationInfo.restaurant_id}/dinein`
    );
  };

  return (
    <>
      <div style={{ padding: "20px 28px 33px" }}>
        <Navbar
          backIcon
          title={t("profile.myReservations", { ns: "translation" })}
          languageIcon
          backIconURL={"/profile"}
        />
      </div>
      <div className={`${styles.my_reservationscreen}`}>
        <div className={`${styles.category_items}`}>
          {category.map((item, index) => {
            return (
              <Button
                variant="outlined"
                style={{
                  backgroundColor: item.isActive ? "#98694E" : "white",
                  color: item.isActive ? "white" : "#3F484E",
                  border: " 1px solid #C0CDD7",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
                onClick={() => handleItems(item, index)}
                key={index}
                className={`${styles.cat_item}`}
                sx={{
                  p: 1,
                  border: " 1px solid #C0CDD7",
                  color: "#40484E",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {t(item.type)}
              </Button>
            );
          })}
        </div>
        <div className={`${styles.my_order_cards}`}>
          {!isLoading &&
          reservationAndQueueResponse &&
          reservationAndQueueResponse.data &&
          reservationAndQueueResponse.data.length === 0 &&
          !isError ? (
            <div className={`${styles.no_reservations}`}>
              <img src="/assets/img/noReservations.svg" alt="" />
              <div className={`${styles.no_reservations_desc}`}>
                {noReservationsText}
              </div>
              <Button
                sx={{
                  width: "326px",
                  height: "56px",
                  fontSize: "22px",
                  fontWeight: "600",
                  backgroundColor: "#98694E",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "rgb(106, 73, 54)",
                  },
                }}
                onClick={() => {
                  navigate(paths.home);
                }}
              >
                {t("mainPage")}
              </Button>
            </div>
          ) : (
            <div>
              {!isLoading &&
                !isError &&
                reservationAndQueueResponse &&
                reservationAndQueueResponse.data &&
                reservationAndQueueResponse.data
                  .map((item, index) => {
                    if (state === "all") {
                      return (
                        <ReservationQueueCard
                          item={item}
                          key={index}
                          handleCancellation={handleCancellation}
                          handleChangeReservationQueue={
                            handleChangeReservationQueue
                          }
                          populateSelectedDineinInfo={
                            populateSelectedDineinInfo
                          }
                        />
                      );
                    } else if (item.dinein_type === state) {
                      return (
                        <ReservationQueueCard
                          item={item}
                          key={index}
                          handleCancellation={handleCancellation}
                          handleChangeReservationQueue={
                            handleChangeReservationQueue
                          }
                          populateSelectedDineinInfo={
                            populateSelectedDineinInfo
                          }
                        />
                      );
                    }
                  })
                  .filter((item) => !!item)}
            </div>
          )}

          {isLoading && <CircularProgress size="large" />}
        </div>
      </div>
      <DineinCancel
        cancelDineine={cancelDineine}
        setCancelDineine={setCancelDineine}
        handleCancel={handleCancellation}
        fromReservation={cancelDineinInfo?.dinein_type === "reservation"}
      />
    </>
  );
};

export default MyReservationScreen;
