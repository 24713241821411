import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    fallbackLng: "en",
    returnObjects: true,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    ns: [
      "common",
      "landingPage",
      "translation",
      "dialog",
      "address",
      "catering",
      "delivery",
      "order",
      "cart",
      "pickup",
      "dinein",
    ],
    nsSeparator: "::",
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: "::",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
