import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import "./style.css";

const ItemHeader = ({ itemName, totalPerson, description }) => {
  return (
    <Box>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Typography variant="h3" className="dinein-item-name">
          {itemName}
        </Typography>

        <div className="dinein-total-persons">
          <img src="/assets/dinein/persons.svg" />

          <Typography>{totalPerson}</Typography>

          <Typography>{"Persons"}</Typography>
        </div>
      </Stack>

      <Typography className="dinein-item-description">{description}</Typography>
    </Box>
  );
};

export default ItemHeader;
