import axiosInstance from "../../axiosInstance";
import API_ENDPOINTS from "../../endpoints";
import { handleApiError } from "../../utils/errorHandling";

const branchQueueStatus = async (branchId, restaurantId) => {
  try {
    const payload = {
      country_id: "3",
      branch_id: branchId.toString(),
      restaurant_id: restaurantId,
    };
    // Make the POST request to get all delivery price.
    const { data } = await axiosInstance.post(
      `${API_ENDPOINTS.RESTAURANT.DINEIN.QUEUE_STATUS}`,
      payload
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default branchQueueStatus;
