import axiosInstance from "../../axiosInstance";
import API_ENDPOINTS from "../../endpoints";
import { handleApiError } from "../../utils/errorHandling";

const createReservation = async (reservationInfo, restaurant_id) => {
  try {
    const payload = {
      guest_allowed: reservationInfo?.guests,
      sitting_area: reservationInfo?.seating,
      first_name: reservationInfo?.firstName,
      last_name: reservationInfo?.lastName,
      mobile_no: reservationInfo?.mobile_no,
      mobile_country_code: reservationInfo?.mobile_country_code,
      branch_id: reservationInfo?.branchId,
      restaurant_id: restaurant_id,
      country_id: "3",
      reservation_date: reservationInfo?.reservationDate,
      reservation_time: reservationInfo?.reservationTime,
    };
    // Make the POST request to get all delivery price.
    const { data } = await axiosInstance.post(
      `${API_ENDPOINTS.RESTAURANT.DINEIN.RESERVATION_REQUEST}/${reservationInfo?.branchId}`,
      payload
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default createReservation;
