import axiosInstance from "../../axiosInstance";
import API_ENDPOINTS from "../../endpoints";
import { handleApiError } from "../../utils/errorHandling";

const getUserQueueInfo = async (restaurantId) => {
  try {
    // Make the GET request to fetch restaurant branch hours.
    const { data } = await axiosInstance.get(
      API_ENDPOINTS.RESTAURANT.DINEIN.EDIT_QUEUE +
        `/1?restaurant_id=${restaurantId}`
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default getUserQueueInfo;
