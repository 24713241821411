import axiosInstance from "../../axiosInstance";
import API_ENDPOINTS from "../../endpoints";
import { handleApiError } from "../../utils/errorHandling";

export const GET_RESERVATIONS_AND_QUEUE_QUERY_KEY = "get_reservations_and_queue_query_key";

const getReservationsAndQueue = async () => {
  try {
    const { data } = await axiosInstance.get(API_ENDPOINTS.RESTAURANT.DINEIN.GET_RESERVATIONS_AND_QUEUE);
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default getReservationsAndQueue;