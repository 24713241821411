const API_ENDPOINTS = {
  HOME: {
    GET_ALL_POPULAR_ITEMS: "/homepage/popular-items",
    GET_ALL_IFTAR_ITEMS: "/homepage/iftar-items",
    GET_ALL_GATHERING_ITEMS: "/homepage/gathering-items",
    GET_ALL_RESTAURANT: "/homepage/restaurants",
    GET_ALL_RESTAURANTS_BANNER: "/homepage/banners",
    SUPPORT_DELIVERY_BY_AREA: "/restaurant/support-delivery-by-area",
    GET_ALL_RESTAURANT_BRANCHES: "/restaurant/all/branches",
  },
  RESTAURANT: {
    GET_RESTAURANT_DETAIL: "/restaurant/",
    GET_NEARBY_RESTAURANT: "/find-nearest-restaurants",
    GET_BRANCHES_AND_HOURS(restaurantId, language = "en") {
      return `/restaurant/${restaurantId}/branches?language=${language}`;
    },
    GET_BRANCH_BY_AREA: "/find-branch-by-delivery-area",
    GET_BRANCH_BY_AREA_ID: "/branch-id-from-delivery-areas",
    DINEIN:{
      QUEUE_REQUEST:"/customer/dinein/queue_request",
      EDIT_QUEUE:"/customer/dinein/edit_queue_request",
      CANCEL_REQUEST:"/customer/dinein/cancel_queue_request",
      QUEUE_STATUS:"/customer/dinein/current_queue_status",
      RESERVATION_REQUEST:"/customer/dinein/reservation_request",
      CANCEL_RESERVATION:"/customer/dinein/cancel_reservation_request",
      EDIT_RESERVATION:"/customer/dinein/edit_reservation_request",
      GET_RESERVATIONS_AND_QUEUE:"/customer/dinein/requests"
    }
  },
  USER: {
    EMAIL_LOGIN: "/email-login",
    MOBILE_LOGIN: "/mobile-login",
    CUSTOMER_REGISTRATION: "/customer/registration",
    UPDATE_PROFILE: "/customer/update-profile",
    VERIFY_OTP: "/customer/verify-otp",
    RESEND_OTP: "/customer/resend-otp",
    INFO: "/customer",
    LOGOUT: "/customer/logout",
  },
  CART: {
    ADD_TO_CART: "/add-to-cart",
    CART_LIST: "/cart-list",
    DELETE_CART: "/delete-cart",
    UPDATE_CART: "/update-cart",
    CHECKOUT: "/checkout/process",
    CLEAR_CART: "/empty-cart",
    APPLY_PROMO_CODE: "/apply-promo-code",
  },
  PAYMENT_METHOD: {
    PAYMENT: "/payment-method",
  },
  MENU: {
    GET_MENU_ITEM_DETAILS: "/restaurant/",
    GET_MENU_ITEM_DETAILS_DIRECT_LINK: "/permalink/menu-item/",
    MENU_ITEM_AVAILABILITY: "/menu-items/check-availability",
  },
  ADDRESS: {
    ADDRESS: "/address",
    AREA: "/delivery-zones/areas",
  },
  COMMON: {
    GET_ALL_COUNTRIES: "/common/countries",
    GET_DELIVERY_CHARGES: "/delivery-charges",
  },
  ORDER: {
    GET_ORDERS: "/order",
    GET_ORDER_DETAILS: "/order/",
    ORDER_RATING: "/order/rating",
    GET_ORDER_STATUS: "/order/status",
    ORDER_CONVERSION: "/order/conversion",
  },
  BRANCH: {
    GET_DELIVERY_CHARGES: "/branch",
  },
};

export default API_ENDPOINTS;
