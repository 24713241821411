import axiosInstance from "../../axiosInstance";
import API_ENDPOINTS from "../../endpoints";
import { handleApiError } from "../../utils/errorHandling";

const cancelReservation = async (reservationId) => {
  try {
    // Make the POST request to get all delivery price.
    const { data } = await axiosInstance.post(
      `${API_ENDPOINTS.RESTAURANT.DINEIN.CANCEL_RESERVATION}/${reservationId}`
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default cancelReservation;
