import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Card, Box } from "@mui/material";
import { paths } from "../../../routes/paths";
import { retrunLanguage } from "../../../utils/languageSelect";

import styles from "../style.module.css";

const ReservationQueueCard = ({
  item,
  handleChangeReservationQueue,
  populateSelectedDineinInfo,
}) => {
  const { t, i18n } = useTranslation("order");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleReceipt = () => {
    navigate(paths.receipt + item.order_id);
  };

  const iconPath = `/assets/img/Dine_In_Icon.svg`;

  return (
    <>
      <Card style={{ border: "1px solid #C0CDD7", marginTop: "10px" }}>
        <div className={`${styles.card_nav}`}>
          <img src={item.restaurant_logo} alt="" />

          <div className={`${styles.card_nav_tags}`}>
            <Button
              sx={{
                p: 1,
                border: " 1px solid #C0CDD7",
                color: "#40484E",
                fontSize: "12px",
                fontWeight: "700",
              }}
              variant="outlined"
              className={`${styles.receipt_tag}`}
              onClick={
                item.status !== "seated"
                  ? () => handleChangeReservationQueue(item)
                  : handleReceipt
              }
            >
              {item.status !== "seated"
                ? item.dinein_type === "reservation"
                  ? t("changeReservation")
                  : t("details")
                : t("reciept")}
            </Button>
          </div>
        </div>
        <div
          className={`${styles.card_content} mx-3`}
          style={{
            borderTop: "1px dashed #707070",
          }}
        >
          <div className={`${styles.card_content_left}`}>
            <div className={`${styles.card_content_left_lt}`}>
              <img src={iconPath} width={"18px"} height={"12px"} alt="" />
              <div>
                <div
                  className={`${styles.card_content_left_text}`}
                  style={{ marginLeft: "2px", lineHeight: "10px" }}
                >
                  {item.dinein_type === "reservation"
                    ? `${t("reservationId", { ns: "dinein" })}: #${
                        item.reservation_id
                      }`
                    : t("queue", { ns: "dinein" })}
                </div>
                <div
                  className={`${styles.card_content_left_text}`}
                  style={{ marginLeft: "2px" }}
                >
                  {t("branch", { ns: "common" })}:{" "}
                  {retrunLanguage(i18n)
                    ? item.branch_name_ar
                    : item.branch_name_en}
                </div>
              </div>
            </div>
            <div
              className={`${styles.card_content_left_text_regular} align-left`}
            >
              {item.dinein_type === "reservation" ? (
                <>
                  {`${t("reservationDate", { ns: "dinein" })}: `}
                  <span style={{ fontWeight: "bold" }}>
                    {item.reservation_date}
                  </span>
                </>
              ) : item.queue_no === 0 ? (
                <>
                  {`${t("currentQueue", { ns: "dinein" })}: `}
                  <span style={{ fontWeight: "bold" }}>
                    {item.queue_status}
                  </span>
                </>
              ) : (
                <>
                  {`${t("yourCurrentQueue", { ns: "dinein" })}: `}
                  <span style={{ fontWeight: "bold" }}>{item.queue_no}</span>
                </>
              )}
            </div>

            {item.dinein_type === "reservation" ? (
              <div
                className={`${styles.card_content_left_text_regular} align-left`}
              >
                {item.status !== "Pending" ? (
                  <>
                    {t("hostedAt")}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {item.reservation_date}
                    </span>{" "}
                    {t("at")}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {item.reservation_time}
                    </span>
                  </>
                ) : (
                  <>
                    {t("reservationTime", { ns: "dinein" })}:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {item.reservation_time}
                    </span>
                  </>
                )}
              </div>
            ) : (
              <div
                className={`${styles.card_content_left_text_regular} align-left`}
              >
                {item.queue_no !== 0 ? (
                  <>
                    {`${t("estimatedWaitingTime", { ns: "dinein" })}: `}
                    <span style={{ fontWeight: "bold" }}>
                      {item.waiting_time}
                    </span>
                  </>
                ) : (
                  <>
                    {`${t("placedAt")} `}
                    <span style={{ fontWeight: "bold" }}>
                      {item.queue_date}
                    </span>
                    {` ${t("at")} `}
                    <span style={{ fontWeight: "bold" }}>
                      {item.queue_time}
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
          {item.status !== "Seated" && (
            <Box
              sx={{
                paddingTop: "30px",
              }}
            >
              <Button
                sx={{
                  border: "1px solid #E02020",
                  color: "#E02020",
                  fontSize: "12px",
                  fontWeight: "700",
                }}
                className={`${styles.cancel_tag}`}
                variant="outlined"
                onClick={() => populateSelectedDineinInfo(item)}
              >
                {t("cancel")}
              </Button>
            </Box>
          )}
        </div>
      </Card>
    </>
  );
};

export default ReservationQueueCard;
