import React from "react";

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MenuItems from "./menuItem";

import "./style.css";
import MenuItemCard from "./menuItemCard";

const AdditionalInformation = ({ allMenus, menuItems, setOpen }) => {
  return (
    <Box className="additional-info-item mt-0 quick-overview">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel2-header"
        >
          <Typography variant="h2">Additional information</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <MenuItems allMenus={allMenus} />

          {menuItems?.map((item) => (
            <MenuItemCard key={item.id} item={item} />
          ))}
        </AccordionDetails>

        <Box sx={{ padding: "0px 28px" }}>
          <Button className="button" onClick={() => setOpen(true)}>
            {"Reserve"}
          </Button>
        </Box>
      </Accordion>
    </Box>
  );
};

export default AdditionalInformation;
