import axiosInstance from "../../axiosInstance";
import API_ENDPOINTS from "../../endpoints";
import { handleApiError } from "../../utils/errorHandling";

const getReservations = async () => {
  try {
    const { data } = await axiosInstance.get(`${API_ENDPOINTS.RESTAURANT.DINEIN.GET_RESERVATIONS_AND_QUEUE}?type=reservation`);
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default getReservations;

