import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import { useQuery } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";

import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

import Navbar from "../../components/header/index";
import Slider from "../../components/carousel/slider/index";
import DraggableSlider from "../../components/draggableSlider";
import { Footer } from "../../components/footer/footerContent";
import RestaurantsOrderTypeButton from "../../components/category/button";
import "../../components/carousel/slider/style.css";
import Card from "../../components/adressList";
import { DialogComponent } from "../../components/dialog";
import ScheduleDialog from "../../components/dialog/scheduleDialog";
import SchedulePicker from "../../components/shared/schedulePicker";
import SelectLocation from "../../components/dialog/selectLocation";
import RemoveFromCart from "../../components/dialog/differentBranchRestaurant";

// import getRestaurantBranchHours from "../../api/restaurant/getRestaurantBranchHours";
import countryList from "../../api/common/getAllCountries";
import getAllArea from "../../api/address/areas";
import getBranchByAreaId from "../../api/restaurant/branches/getBranchIdBasedOnAreaId";
import menuItemsAvailability from "../../api/menu/menuItemsAvailability";
import deleteCart from "../../api/cart/deleteCartItem";

import getAllPopularItems, {
  GET_ALL_POPULAR_ITEMS_QUERY_KEY,
} from "../../api/home/getAllPopularItems";

import getIftarItems, {
  GET_IFTAR_ITEMS_QUERY_KEY,
} from "../../api/home/getIftarItems";
import getGatheringItems, {
  GET_GATHERING_ITEMS_QUERY_KEY,
} from "../../api/home/getGatheringItems";
import supportDeliveryByArea from "../../api/home/supportDeliveryByArea";
import getAllRestaurantsBranches from "../../api/home/getAllRestaurantsBranches";
import getAllAddress from "../../api/address/getAddress";

import { addCountry } from "../../store/reducers/authSlice";
import {
  getAllBranches,
  getBranchHours,
  getSupportResturants,
  removeCategoryItemList,
  removeCategoryList,
  removeResturantsDetails,
  resetSupportResturants,
} from "../../store/reducers/resturantsSlice";
// import { getAreas } from "../../store/reducers/addressSlice";
import { addSelectedAddress } from "../../store/reducers/userLocationSlice";
import { removeFromCart } from "../../store/reducers/cartSlice";
import { getAddress } from "../../store/reducers/addressSlice";

import {
  // flattenArray,
  getDistanceFromLatLonInKm,
  getFullAddress,
  selectChooseAddress,
} from "../../utils/getAddress";
import { retrunLanguage } from "../../utils/languageSelect";

import { paths } from "../../routes/paths";

import "./style.css";
import PickupFrom from "../../components/dialog/pickupDialog";
import { filterPickupRestaurants } from "../../utils/filterPickupRestaurant";

const Home = () => {
  const { t, i18n } = useTranslation("landingPage");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const addressData = useSelector((state) => state.address.addressData);
  const branchHours = useSelector((state) => state.resturants.branchHours);
  const pickupBranchId = useSelector(
    (state) => state.resturants.pickupBranchId
  );
  const allAreas = useSelector((state) => state.address.allAreas);
  const resturantsData = useSelector(
    (state) => state.resturants.resturantsData
  );
  const cart = useSelector((state) => state.cart.cart);
  const supportRestaurant = useSelector(
    (state) => state.resturants.supportRestaurant
  );
  const allBranches = useSelector((state) => state.resturants.allBranches);

  const token = localStorage.getItem("token");
  const userSelected = localStorage.getItem("selected");
  const userLocation = localStorage.getItem("location");
  const isCountry = localStorage.getItem("country");
  const matchingArea = JSON.parse(localStorage.getItem("matchingArea"));

  useEffect(() => {
    localStorage.removeItem("restaurantIdForRedirect");
    localStorage.removeItem("preserevedBranchId");
    localStorage.removeItem("preserevedRestaurantID");

    dispatch(removeResturantsDetails());
    dispatch(removeCategoryItemList());
    dispatch(removeCategoryList());

    localStorage.removeItem("restaurantID");
    // localStorage.removeItem("branchId");
    localStorage.removeItem("restaurantName");
    localStorage.removeItem("menuDetailId");
  }, [cart, resturantsData]);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressId, setAddressID] = useState();
  const [delivery_charges, setDeliveryCharge] = useState();
  const [delivery_time, setDeliveryTime] = useState();
  const [name_en, setNameEn] = useState();
  const [restName, setRestName] = useState("");

  const addRestauantName = (id) => {
    const restName = resturantsData?.find((rest) => rest.id === id);

    return restName?.name_en;
  };

  useEffect(() => {
    if (userLocation !== null && userSelected === null) {
      const parsedLocation = JSON.parse(userLocation);

      for (let i = 0; i < addressData?.length; i++) {
        if (
          parseFloat(+addressData[i].latitude) ===
            parseFloat(parsedLocation.lat) &&
          parseFloat(+addressData[i].longitude) ===
            parseFloat(parsedLocation.lng)
        ) {
          setAddressID(addressData[i].id);

          if (!Array.isArray(addressData[i]?.delivery_charge_area_info)) {
            setDeliveryCharge(
              addressData[
                i
              ]?.delivery_charge_area_info?.delivery_charges?.split(" ")[1]
            );
            setDeliveryTime(
              addressData[i]?.delivery_charge_area_info?.delivery_time
            );
            setNameEn(addressData[i]?.delivery_charge_area_info?.name_en);
            setRestName(
              addRestauantName(
                addressData[i]?.delivery_charge_area_info?.restaurant_id
              )
            );
          }

          localStorage.setItem(
            "area_id",
            addressData[i]?.delivery_charge_area_info.id
          );

          const matchingArea = {
            area_name_en: addressData[i]?.delivery_charge_area_info?.name_en,
            area_name_ar: addressData[i]?.delivery_charge_area_info.name_ar,
          };

          localStorage.setItem("matchingArea", JSON.stringify(matchingArea));

          getFullAddress(
            +addressData[i].latitude,
            +addressData[i].longitude,
            setSelectedAddress
          );
        }
      }
    }
  }, [localStorage]);

  useEffect(() => {
    if (selectedAddress !== null) {
      const addToStore = {
        lat: selectedAddress.lat,
        lng: selectedAddress.lng,
        address: selectedAddress.address,
        addressId,
        delivery_charges,
        delivery_time,
        name_en,
        restaurantName: restName,
      };

      localStorage?.setItem("selected", JSON.stringify(addToStore));
      dispatch(addSelectedAddress(addToStore));
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (!isCountry) {
      fetchAllCountries();
    }
  }, []);

  const fetchAllCountries = () => {
    countryList()
      .then((res) => {
        const defaultCountry = res.data.find((val) => val.is_default == "Yes");

        localStorage.setItem("country", JSON.stringify(defaultCountry));

        // const restaurant_id = localStorage.getItem("restaurantID");
        // const branch_id = localStorage.getItem("branchId");

        // if (restaurant_id !== null || branch_id !== null) {
        //   fetchAllAreas(restaurant_id, branch_id);
        // }
        dispatch(addCountry(defaultCountry));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const fetchAllAreas = () => {
  //   getAllArea()
  //     .then((res) => {
  //       const flattenedArray = flattenArray(res.data);

  //       dispatch(getAreas(flattenedArray));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [filterClassNear, setToggleFilterClassNear] = useState(false);
  const [filterSelectedNow, setFilterSelectedNow] = useState("");
  const [filterClassDelivery, setToggleFilterClassDelivery] = useState(false);
  const [fullAddress, setFullAddress] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const [restaurantName, setRestaurantName] = useState("");
  const [distanceIsFar, setDistanceIsFar] = useState(false);
  const [fromPopular, setFromPopular] = useState(false);
  const [popularId, setPopularId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [newText, setNewText] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [restaurantOpen, setRestaurantOpen] = useState(false);
  const [chooseFullAddress, setChooseFullAddress] = useState(null);
  const [openAddressToChoose, setOpenAddressToChoose] = useState(null);
  // const [distancesLessThan50, setDistancesLessThan50] = useState([]);
  const [clearCartModal, setClearCartModal] = useState(false);
  const [removingItem, setRemovingItem] = useState(false);
  const [changeAddressDetails, setChangeAddressDetails] = useState(null);
  const [itemsAfterRemove, setItemsAfterRemove] = useState([]);
  const [dispatchCount, setDispatchCount] = useState(0);
  const [pickupFromDialog, setPickupFromDialog] = useState(false);

  const [typesState, setTypesState] = useState({
    dinein: false,
    catering: false,
    delivery: false,
    pickup: false,
  });
  const [selectedFilter, setSelectedFilter] = useState(null);

  const parentDiv = document?.getElementById("parent-of-all");
  const parentIcon = document?.getElementsByClassName("inner-svg-container");
  const divs = parentDiv?.getElementsByClassName("restaurantCards");
  const ranOnceRef = useRef(false);

  const fetchFunctionAddresses = async () => {
    getAllAddress(token).then((res) => {
      if (res.success === "ok") {
        const filterAddress = res.data.filter(
          (address) => !Array.isArray(address.delivery_charge_area_info)
        );

        dispatch(getAddress(filterAddress));
      }
    });
  };

  useEffect(() => {
    if (token && !addressData.length) {
      fetchFunctionAddresses();
    }
  }, []);

  const fetchSupportRestaurantByArea = (serviceType) => {
    supportDeliveryByArea(
      matchingArea?.area_name_en,
      serviceType?.toLowerCase(),
      token
    ).then((res) => {
      dispatch(getSupportResturants(res?.data));
    });
  };

  const fetchAllBranches = () => {
    getAllRestaurantsBranches().then((res) => {
      dispatch(getBranchHours(res?.data));
      dispatch(getAllBranches(res.data));
    });
  };

  const extractSelectedFilter = () => {
    // Use Object.entries() to get an array of [key, value] pairs
    const entry = Object.entries(typesState).find(([key, value]) => value);

    // If a true key-value pair is found, return the key; otherwise, return null
    return entry ? entry[0] : null;
  };

  useEffect(() => {
    // if (resturantsData?.length) {
    if (!ranOnceRef.current) {
      fetchAllBranches();
      ranOnceRef.current = true;
    }
    //     removeBrownClasses();
    //     let promises = [];
    //     resturantsData?.forEach((restaurant) => {
    //       const idToCheck = restaurant.id;
    //       promises.push(getRestaurantBranchHours(idToCheck));
    //     });
    //     Promise.all(promises)
    //       .then((responses) => {
    //         let allItems = [];
    //         responses.forEach((res) => {
    //           if (res.status === "ok") {
    //             res?.data?.forEach((item) => {
    //               if (!allItems.includes(item)) {
    //                 allItems.push(item);
    //               }
    //             });
    //           }
    //         });
    //         dispatch(getBranchHours(allItems));
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    // }
  }, []);

  const chooseAddress = (item) => {
    selectChooseAddress(
      item,
      setAddressID,
      setDeliveryCharge,
      setDeliveryTime,
      setNameEn,
      setRestName,
      getFullAddress,
      setFullAddress,
      addRestauantName
    );
  };

  const changeAddressAfterSelect = (item) => {
    getBranchByAreaId(
      cart[0]?.restaurant_id,
      JSON.parse(localStorage.getItem("country"))?.id,
      item?.delivery_charge_area_name_en,
      dispatch
    );

    selectChooseAddress(
      item,
      setAddressID,
      setDeliveryCharge,
      setDeliveryTime,
      setNameEn,
      setRestName,
      getFullAddress,
      setChooseFullAddress,
      addRestauantName
    );
  };

  const onChooseFullAddress = (item) => {
    if (cart?.length > 0) {
      const itemIds = cart?.map((item) => {
        return item.id;
      });

      const availabilityObject = {
        restaurant_id: item.delivery_charge_area_info.restaurant_id,
        branch_id: item.delivery_charge_area_info.branch_id,
        item_ids: itemIds,
      };

      menuItemsAvailability(availabilityObject, token).then((itemRes) => {
        if (itemRes?.data?.unavailable_item_ids?.length > 0) {
          const removeFromCart = cart?.filter((item) =>
            itemRes?.data?.unavailable_item_ids.includes(item.id)
          );

          setItemsAfterRemove(removeFromCart);

          setClearCartModal(true);
          setChangeAddressDetails({
            address: item,
          });
        } else {
          changeAddressAfterSelect(item);
        }
      });
    } else {
      changeAddressAfterSelect(item);
    }
  };

  const clearFunction = () => {
    setRemovingItem(true);

    itemsAfterRemove?.map((item) => {
      deleteCart(
        {
          cart_id: item.cart_id,
          item_id: item.id,
          item_code: item.item_code,
        },
        token
      )
        .then(() => {
          dispatch(removeFromCart(item.id));

          setDispatchCount((prevCount) => prevCount + 1);
        })
        .catch(() => {
          setRemovingItem(false);
        });
    });
  };

  useEffect(() => {
    if (
      dispatchCount === itemsAfterRemove?.length &&
      changeAddressDetails?.address !== undefined
    ) {
      setRemovingItem(false);

      changeAddressAfterSelect(changeAddressDetails?.address);

      setClearCartModal(false);
    }
  }, [dispatchCount, itemsAfterRemove]);

  useEffect(() => {
    let pushedWithDistance = [];

    const filterBranch = branchHours?.filter(
      (rest) => rest.restaurant_id === cart[0]?.restaurant_id
    );

    if (chooseFullAddress !== null) {
      if (cart?.length === 0) {
        const addToStore = {
          ...chooseFullAddress,
          addressId,
          delivery_charges,
          delivery_time,
          // minOrderAmount,
          name_en,
          restaurantName: restName,
        };

        localStorage.setItem("selected", JSON.stringify(addToStore));
        dispatch(addSelectedAddress(addToStore));

        navigate(paths.cart);
        return;
      }

      if (branchHours.length && filterBranch?.length) {
        for (let i = 0; i < filterBranch?.length; i++) {
          const resLat = +filterBranch[i]?.latitude;
          const resLng = +filterBranch[i]?.longitude;

          const distance = getDistanceFromLatLonInKm(
            resLat,
            resLng,
            chooseFullAddress.lat,
            chooseFullAddress.lng
          );

          const restaurant = resturantsData.find(
            (res) => res.id === cart[0]?.restaurant_id
          );

          if (distance > 50) {
            setRestaurantName(restaurant?.name_en);
            setDistanceIsFar(true);
            setOpenAddressToChoose(false);
          } else {
            pushedWithDistance.push({
              ...filterBranch[i],
              distance,
            });

            checkWithBranchIdDeliverZonesChoose(pushedWithDistance);
          }
        }
      }
    }
  }, [chooseFullAddress, branchHours]);

  const checkWithBranchIdDeliverZonesChoose = (allDistancesLessThan50) => {
    // Find the smallest distance and associated location
    const smallestDistance = Math.min(
      ...allDistancesLessThan50.map((item) => item.distance)
    );
    const smallestDistanceLocation = allDistancesLessThan50.find(
      (item) => item.distance === smallestDistance
    );

    if (smallestDistanceLocation) {
      setDistanceIsFar(false);
      setOpenAddressToChoose(false);

      checkNearByRest();
    }
  };

  const checkNearByRest = () => {
    let addToStore = {
      ...chooseFullAddress,
      addressId,
    };

    if (name_en) {
      addToStore = {
        ...addToStore,
        delivery_charges,
        delivery_time,
        name_en,
        restaurantName: restName,
      };
    }

    localStorage.setItem("selected", JSON.stringify(addToStore));
    dispatch(addSelectedAddress(addToStore));

    const restaurant = resturantsData.find(
      (res) => res.id === cart[0]?.restaurant_id
    );

    const filterRestaurant = branchHours.filter(
      (branch) => branch.restaurant_id === restaurant.id
    );

    if (filterRestaurant?.length === 0) {
      setRestaurantName(restaurant?.name_en);
      setDistanceIsFar(true);
      setOpenAddressToChoose(false);
    } else {
      if (localStorage.getItem("isAsap") === "true") {
        for (let i = 0; i < filterRestaurant?.length; i++) {
          const date = new Date();
          const options = { weekday: "long" };
          const dayName = date.toLocaleDateString("en-US", options);

          const findStatus = filterRestaurant[i]?.branch_hours?.filter(
            (branch) => branch.week_day === dayName
          );

          if (findStatus[0]?.openingStatus === "Open") {
            setRestaurantOpen(false);
            navigate(paths?.cart, {
              state: { fromHome: true, path: "/home" },
            });

            break;
          } else {
            setRestaurantOpen(true);
          }
        }
      } else {
        setRestaurantOpen(false);
        navigate(paths?.cart, {
          state: { fromHome: true, path: "/home" },
        });
      }
    }
  };

  useEffect(() => {
    if (fullAddress !== null) {
      let pushedWithDistance = [];
      fetchSupportRestaurantByArea(selectedText);

      if (filterSelectedNow === "") {
        if (branchHours.length) {
          for (let i = 0; i < branchHours.length; i++) {
            const resLat = +branchHours[i]?.latitude;
            const resLng = +branchHours[i]?.longitude;

            const distance = getDistanceFromLatLonInKm(
              resLat,
              resLng,
              fullAddress.lat,
              fullAddress.lng
            );

            if (distance > 50) {
              const restaurant = resturantsData.find(
                (res) => res.id === restaurantId
              );

              setNewText(t("newLocation"));
              setRestaurantName(restaurant?.name_en);
              setDistanceIsFar(true);
              setOpenAddressPopup(false);
            } else {
              pushedWithDistance.push({
                ...branchHours[i],
                distance,
              });

              checkWithBranchIdDeliverZonesFullAddress(pushedWithDistance);

              break;
            }
          }
        }
      } else {
        let addToStore = {
          ...fullAddress,
          addressId,
        };

        if (name_en) {
          addToStore = {
            ...addToStore,
            delivery_charges,
            delivery_time,
            name_en,
            restaurantName: restName,
          };
        }

        localStorage.setItem("selected", JSON.stringify(addToStore));
        dispatch(addSelectedAddress(addToStore));
        setOpenAddressPopup(false);

        if (filterSelectedNow?.toLowerCase() === "near me" && addToStore) {
          navigate(paths.nearMe, { state: selectedFilter });
        }
      }
    }
  }, [fullAddress, branchHours]);

  const checkWithBranchIdDeliverZonesFullAddress = (allDistancesLessThan50) => {
    // Find the smallest distance and associated location
    const smallestDistance = Math.min(
      ...allDistancesLessThan50.map((item) => item.distance)
    );
    const smallestDistanceLocation = allDistancesLessThan50.find(
      (item) => item.distance === smallestDistance
    );

    if (smallestDistanceLocation) {
      setNewText("");
      setDistanceIsFar(false);
      setOpenAddressPopup(false);

      let addToStore = {
        ...fullAddress,
        addressId,
      };

      if (name_en) {
        addToStore = {
          ...addToStore,
          delivery_charges,
          delivery_time,
          // minOrderAmount,
          name_en,
          restaurantName: restName,
        };
      }

      localStorage.setItem("selected", JSON.stringify(addToStore));
      dispatch(addSelectedAddress(addToStore));

      if (restaurantId === null) {
        setOpenAddressPopup(false);
      }

      if (restaurantId !== null && !fromPopular) {
        goToRestaurantMenuPage(restaurantId);
      }

      if (fromPopular && popularId !== null) {
        navigate(paths.details + "/" + popularId, {
          state: {
            id: popularId,
            loc: "popular",
            restaurant_id: restaurantId,
            branch_id: branchId,
          },
        });
      }
    }
  };

  const navigateUser = () => {
    const restaurant = resturantsData.find((res) => res.id === restaurantId);

    navigate(paths.location, {
      state: {
        path: "/home",
        restaurant_id: restaurantId,
        restaurantName: restaurant?.name_en,
        filterSelectedNow:
          filterSelectedNow !== "" && filterSelectedNow === t("nearMe")
            ? selectedFilter
            : "",
      },
    });
  };

  const onNavigateUser = () => {
    const restaurant = resturantsData.find(
      (res) => res.id === cart[0]?.restaurant_id
    );

    navigate(paths.location, {
      state: {
        path: "menu/restaurant",
        restaurant_id: cart[0]?.id,
        restaurantName: restaurant?.name_en,
        addNew: true,
      },
    });
  };

  // Removing border and background color from icon if Delivers to me is not selected
  const removeClasses = () => {
    // Remove border from restaurant image
    for (let i = 0; i < divs?.length; i++) {
      divs[i].classList.remove("delivery-true");
    }

    // Remove background color from icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      icons.forEach((icon) => {
        if (icon.id === "icon-row") {
          icon.classList.remove("delivers-image");
        }
      });
    }
  };

  // Removing border and background color from icon if Available to deliver is not selected
  const removeBrownClasses = () => {
    // Remove border from restaurant image
    for (let i = 0; i < divs?.length; i++) {
      divs[i].classList.remove("add-brown-border");
    }

    // Remove background color from icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      icons.forEach((icon) => {
        if (icon.id === "icon-row") {
          icon.classList.remove("bg-brown");
        }
      });
    }
  };

  // Add border and background color if Available to deliver is selected
  const addBrownClasses = () => {
    // Add border to restaurant image
    for (let i = 0; i < divs?.length; i++) {
      supportRestaurant?.map((check) => {
        if (check?.id === +divs[i].getAttribute("data-type")) {
          if (check?.delivery_status === 1) {
            divs[i].classList.add("add-brown-border");
          } else {
            divs[i].classList.remove("add-brown-border");
          }
        }
      });
    }

    // Add background color to icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      supportRestaurant?.map((check) => {
        if (check?.id === +parentIcon[i].getAttribute("data-type")) {
          if (check?.delivery_status === 1) {
            icons.forEach((icon) => {
              if (icon.id === "icon-row") {
                icon.classList.add("bg-brown");
              }
            });
          } else {
            icons.forEach((icon) => {
              if (icon.id === "icon-row") {
                icon.classList.add("bg-brown");
              }
            });
          }
        }
      });
    }
  };

  // Add border and background color if Delivers to me is selected
  const addClasses = () => {
    // Add border to restaurant image
    for (let i = 0; i < divs?.length; i++) {
      supportRestaurant?.map((check) => {
        if (check?.id === +divs[i].getAttribute("data-type")) {
          if (check?.delivery_status === 1) {
            divs[i].classList.add("delivery-true");
          } else {
            divs[i].classList.remove("delivery-true");
          }
        }
      });
    }

    // Add background color to icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      supportRestaurant?.map((check) => {
        if (check?.id === +parentIcon[i].getAttribute("data-type")) {
          if (check?.delivery_status === 1) {
            icons.forEach((icon) => {
              if (icon.id === "icon-row") {
                icon.classList.add("delivers-image");
              }
            });
          } else {
            icons.forEach((icon) => {
              if (icon.id === "icon-row") {
                icon.classList.remove("delivers-image");
              }
            });
          }
        }
      });
    }
  };

  const handleClose = () => {
    setOpenAddressPopup(false);
    setToggleFilterClassNear(!filterClassNear);
    setToggleFilterClassDelivery(!filterClassDelivery);
    setFilterSelectedNow("");
    setDistanceIsFar(false);
    setFromPopular(false);
    setNewText("");
    removeClasses();
  };

  const restaurantClickScript = (resturantsData) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "productClick",
      ecommerce: {
        click: {
          actionField: { list: "Search Results" }, // Optional list property.
          products: [
            {
              name: resturantsData.name_en, // Name or ID is required.
              id: resturantsData.id,
            },
          ],
        },
      },
    });
  };

  const goToRestaurantMenuPage = (id) => {
    restaurantClickScript(resturantsData);
    localStorage.setItem("restaurantID", id);
    navigate(paths.menu + `/restaurant/${id}`);
  };

  const anyStateTrue = (stateObject) => {
    return Object.values(stateObject).some((state) => state === true);
  };

  useEffect(() => {
    if (token) {
      if (anyStateTrue(typesState) && matchingArea) {
        fetchSupportRestaurantByArea(selectedText);
      } else if (!anyStateTrue(typesState)) {
        dispatch(resetSupportResturants());
      }
    }
  }, [typesState, selectedText]);

  useEffect(() => {
    setSelectedFilter(extractSelectedFilter());
  }, [typesState]);

  // const findSupportedRestaurants = () => {
  //   let status;

  //   supportRestaurant?.map((restaurant) => {
  //     if (selectedText === t("dinein")) {
  //       status = restaurant.dinein_status;
  //     }
  //     if (selectedText === t("catering")) {
  //       status = restaurant.catering_status;
  //     }
  //     if (selectedText === t("delivery")) {
  //       status = restaurant.delivery_status;
  //     }
  //     if (selectedText === t("pickup")) {
  //       status = restaurant.pickup_status;
  //     }
  //   });

  //   return status;
  // };

  useEffect(() => {
    // Adding green border to Restaurant Image
    if (
      filterClassNear &&
      selectedText === t("delivery") &&
      filterSelectedNow !== t("availableForDelivery") &&
      supportRestaurant?.length
    ) {
      removeBrownClasses();

      if (matchingArea) {
        addClasses();
      }
    }

    // Adding brown border to Restaurant Image
    if (
      selectedText === t("delivery") &&
      filterClassDelivery &&
      !filterClassNear
    ) {
      if (matchingArea) {
        addBrownClasses();
      }
    }

    // Removing border from Restaurant Image
    if (
      selectedText === t("delivery") &&
      !filterClassDelivery &&
      !filterClassNear
    ) {
      removeClasses();
      removeBrownClasses();
    }
  }, [
    filterClassNear,
    fullAddress,
    filterClassDelivery,
    selectedText,
    filterSelectedNow,
    supportRestaurant,
  ]);

  const pickTime = () => {
    const pickupTime =
      pickupBranchId !== null &&
      allAreas
        .find(
          (area) =>
            area?.branch_id === pickupBranchId &&
            area.restaurant_id === cart[0]?.restaurant_id
        )
        ?.pickup_time?.trim()
        ?.split(" ")[0];

    return pickupTime ? +pickupTime : 30;
  };

  return (
    <div className="home-styling container-fluid">
      <div style={{ padding: "8px" }}>
        <Navbar
          profile
          // searchIcon
          setOpenAddressPopup={setOpenAddressToChoose}
          logo
          cartIcon
          profileIconUrl={token ? paths.profile : paths.guest_profile}
          fromHome={true}
          setPickupFromDialog={setPickupFromDialog}
        />
      </div>
      <div className="card-spacing">
        <Slider />
      </div>
      <RestaurantsOrderTypeButton
        setOpenAddressPopup={
          userSelected === null && token !== null ? setOpenAddressPopup : null
        }
        openSelectAddress={setOpenAddressPopup}
        setToggleFilterClassNear={setToggleFilterClassNear}
        filterClassNear={filterClassNear}
        filterSelectedNow={filterSelectedNow}
        setFilterSelectedNow={setFilterSelectedNow}
        goToRestaurantMenuPage={goToRestaurantMenuPage}
        setRestaurantId={setRestaurantId}
        setRestaurantName={setRestaurantName}
        filterClassDelivery={filterClassDelivery}
        setToggleFilterClassDelivery={setToggleFilterClassDelivery}
        selectedText={selectedText}
        setSelectedText={setSelectedText}
        // fetchSupportRestaurantByArea={fetchSupportRestaurantByArea}
        typesState={typesState}
        setTypesState={setTypesState}
        selectedFilter={selectedFilter}
      />
      {/* <BasicCard /> */}
      <DraggableSlider
        selectedLocation={userSelected === null}
        setOpenAddressPopup={setOpenAddressPopup}
        setFromPopular={setFromPopular}
        setPopularId={setPopularId}
        setBranchId={setBranchId}
        setRestaurantId={setRestaurantId}
        distanceIsFar={distanceIsFar}
        setDistanceIsFar={setDistanceIsFar}
        setRestaurantOpen={setRestaurantOpen}
        apiCall={getAllPopularItems}
        apiQuery={GET_ALL_POPULAR_ITEMS_QUERY_KEY}
        title={t("popularDishes")}
      />
      <DraggableSlider
        selectedLocation={userSelected === null}
        setOpenAddressPopup={setOpenAddressPopup}
        setFromPopular={setFromPopular}
        setPopularId={setPopularId}
        setBranchId={setBranchId}
        setRestaurantId={setRestaurantId}
        distanceIsFar={distanceIsFar}
        setDistanceIsFar={setDistanceIsFar}
        setRestaurantOpen={setRestaurantOpen}
        apiCall={getIftarItems}
        apiQuery={GET_IFTAR_ITEMS_QUERY_KEY}
        title={t("gatherSelections")}
      />
      <DraggableSlider
        selectedLocation={userSelected === null}
        setOpenAddressPopup={setOpenAddressPopup}
        setFromPopular={setFromPopular}
        setPopularId={setPopularId}
        setBranchId={setBranchId}
        setRestaurantId={setRestaurantId}
        distanceIsFar={distanceIsFar}
        setDistanceIsFar={setDistanceIsFar}
        setRestaurantOpen={setRestaurantOpen}
        apiCall={getGatheringItems}
        apiQuery={GET_GATHERING_ITEMS_QUERY_KEY}
        title={t("cakeSelections")}
      />
      <Box
        sx={{
          marginTop: "60px",
        }}
      >
        <Footer showFooterSection={true} />
      </Box>

      <Dialog
        open={openAddressPopup}
        onClose={() => handleClose()}
        sx={{
          margin: "0",
          textAlign: "center",
        }}
        PaperProps={{
          sx: {
            overflow: "visible",
            margin: "0",
            height: "310px",
            bottom: "0",
            position: "fixed",
            width: "375px",
            left: retrunLanguage(i18n) ? "unset" : "0",
            right: retrunLanguage(i18n) ? "0" : "unset",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",

            "@media (max-width: 500px)": {
              width: "100%",
            },
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {t("chooseYourLocation", { ns: "common" })}
        </DialogTitle>
        <DialogContent
          sx={{
            fontFamily: "Titillium Web, sans-serif",
            fontWeight: 600,
            scrollbarWidth: "thin",
          }}
        >
          {addressData.map((data) => (
            <div key={data.id} className="parent-div">
              <Card
                iconUrl={
                  data.address_type === "house"
                    ? "/assets/img/salmiya home.svg"
                    : data.address_type === "appartment"
                    ? "/assets/img/salmiya appart.svg"
                    : "/assets/img/salmiya office.svg"
                }
                data={data}
                home={true}
                chooseAddress={() => chooseAddress(data)}
              />
            </div>
          ))}
        </DialogContent>
        <div className="button-div">
          <Button className="location-button" onClick={navigateUser}>
            {addressData?.length === 0
              ? t("addNewAddress", { ns: "common" })
              : t("chooseNewLocation", { ns: "common" })}
          </Button>
        </div>
      </Dialog>

      <SelectLocation
        openAddressPopup={openAddressToChoose}
        handleClose={() => setOpenAddressToChoose(false)}
        chooseAddress={onChooseFullAddress}
        navigateUser={onNavigateUser}
      />

      <DialogComponent
        notDeliver={distanceIsFar}
        setNotDeliver={setDistanceIsFar}
        restaurantName={restaurantName}
        fromDetail={true}
        resturantId={restaurantId}
        location={location}
        newText={newText}
        home={true}
      />

      <SchedulePicker
        openDatePicker={openSchedulePopup}
        setOpenDatePicker={setOpenSchedulePopup}
        showTimePicker={showTimePicker}
        setShowTimePicker={setShowTimePicker}
      />

      <ScheduleDialog
        restaurantOpen={restaurantOpen}
        setRestaurantOpen={setRestaurantOpen}
        setOpenSchedulePopup={setOpenSchedulePopup}
        home={true}
      />

      <RemoveFromCart
        clearCartModal={clearCartModal}
        setClearCartModal={setClearCartModal}
        textToShow={t("branchChangeWraning", { ns: "dialog" })}
        clearFunction={clearFunction}
        itemsAfterRemove={itemsAfterRemove}
        removingItem={removingItem}
      />

      {/* <ScheduleOrder
        branchHours={branchHours}
        setRestaurantOpen={setRestaurantOpen}
        orderTime={pickTime}
        setOpenDatePicker={setOpenSchedulePopup}
        openDatePicker={openSchedulePopup}
        orderType={"pickup"}
        selected={userSelected}
        navigateTo={pickupFromDialog ? "/cart" : null}
      /> */}

      <PickupFrom
        pickupFromDialog={pickupFromDialog}
        handleClose={() => setPickupFromDialog(false)}
        branchHours={filterPickupRestaurants(
          allBranches?.filter(
            (branch) => branch?.restaurant_id === cart[0]?.restaurant_id
          ),
          resturantsData
        )}
        restaurant_logo={
          allBranches?.find(
            (branch) => branch?.restaurant_id === cart[0]?.restaurant_id
          )?.restaurant_logo
        }
        setOpenDatePicker={setOpenSchedulePopup}
        navigateTo={pickupFromDialog ? "/cart" : null}
      />
    </div>
  );
};

export default Home;
