import axiosInstance from "../../axiosInstance";
import API_ENDPOINTS from "../../endpoints";
import { handleApiError } from "../../utils/errorHandling";

const editReservation = async (reservationInfo, reservationId) => {
  try {
    const payload = {
      guest_allowed: reservationInfo?.guests,
      sitting_area: reservationInfo?.seatingArea,
      first_name: reservationInfo?.firstName,
      last_name: reservationInfo?.lastName,
      mobile_no: reservationInfo?.mobile_no,
      mobile_country_code: reservationInfo?.mobile_country_code,
      branch_id: reservationInfo?.branch_id,
      restaurant_id:reservationInfo?.restaurantId,
      country_id:"3",
      reservation_date:reservationInfo?.reservationDate,
      reservation_time:reservationInfo?.reservationTime,
    };
    // Make the POST request to get all delivery price.
    const { data } = await axiosInstance.post(
      `${API_ENDPOINTS.RESTAURANT.DINEIN.EDIT_RESERVATION}/${reservationId}`,
      payload   
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default editReservation;
