export const convertTo24Hour = (dateStr, timeStr) => {
    const [month, day, year] = dateStr.split('-');
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
    
    if (hours === '12') {
      hours = '00';
    }
    if (modifier.toLowerCase() === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
  
    // Create a Date object with the correct year, month, day, hours, and minutes
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
  };