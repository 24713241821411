import React, { useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import QueueCard from "./queueCard";
import DineinCancel from "../../../dialog/dineinCancel";
import { useState } from "react";
import DineinChangeBranch from "../../../dialog/dineinChangeBranch";
import { DineInConfirm } from "../../../dialog/dineInConfirm";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setDineinBranchesInfo,
  setDineinQueueCustomerInfo,
  removeQueueRequest,
  updateDineinQueueCustomerInfo,
  resetQueue,
} from "../../../../store/reducers/resturantsSlice";
import { paths } from "../../../../routes/paths";
import joinQueue from "../../../../api/restaurant/dineIn/joinQueue";
import getDineInBranchInfo from "../../../../api/restaurant/dineIn/getDineInBranchInfo";
import cancelQueue from "../../../../api/restaurant/dineIn/cancelQueue";
import editQueue from "../../../../api/restaurant/dineIn/editQueue";
import _ from "lodash";
import getUserQueueInfo from "../../../../api/restaurant/dineIn/getUserQueueInfo";

const settings = {
  dots: true,
  speed: 200,
  slidesToShow: 1,
  autoplaySpeed: 1000,
  appendDots: (dots) => (
    <div className="append-dots">
      <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
    </div>
  ),
  customPaging: () => <div className="custom-paging" />,
  arrows: false,
  fade: false,
  infinite: false,
  className: "center",
  centerMode: true,
  centerPadding: "60px",
};

const DineinQueue = ({ activeButton, restaurant_id }) => {
  const token = localStorage?.getItem("token");
  const isLoggedIn = token !== null;

  const [cancelDineine, setCancelDineine] = useState(false);
  const [dineInConfirmDialog, setDineInConfirmDialog] = useState(false);
  const [startEditing, setStartEditing] = useState(false);
  const userQueues = useSelector(
    (state) => state?.resturants?.dineIn?.userQueueInfo
  );
  const [dineinBranchInfo, setBranchInfo] = useState();
  const [isUserInQueue, setIsUserInQueue] = useState(false);
  const [queueInfoConfirm, setQueueInfoConfirm] = useState();
  const userQueueInfo =
    userQueues?.find((element) => element.restaurant_id == restaurant_id) ||
    {};
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseQueue = () => {
    setDineInConfirmDialog(false);
  };
  const handleQueueCancel = () => {
    const queueNo = userQueueInfo?.queue_id;

    cancelQueue(queueNo)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(removeQueueRequest(queueNo));
          setQueueInfoConfirm(null);
          setCancelDineine(false);
        }
      })
      .catch((e) => console.error(e));
  };

  const handleJoinQueue = (queueInfo, branchName) => {
    const sharedState = {
      restaurant_id: +restaurant_id,
      path: location.pathname,
      guest: true,
      filterSelectedNow: "dinein",
    };
    if (isLoggedIn) {
      joinQueue(queueInfo)
        .then((res) => {
          const queueInfo = {
            ...res?.data,
            branch_name: branchName,
          };
          setQueueInfoConfirm(queueInfo);
          dispatch(setDineinQueueCustomerInfo(queueInfo));
          setDineInConfirmDialog(true);
        })
        .catch((e) => console.error(e));
    } else {
      dispatch(setDineinQueueCustomerInfo(queueInfo));
      navigate(paths.phone_verification_index, {
        state: sharedState,
      });
    }
  };
  const handleChangeBranch = (queueInfo, branchName) => {
    handleQueueCancel();
    handleJoinQueue(queueInfo, branchName);
  };
  const handleUpdateQueueDetails = (queueInfo, queueId, branchName) => {
    editQueue(queueInfo, queueId)
      .then((res) => {
        const queueInformation = {
          ...res?.data,
          restaurant_id: restaurant_id,
          branch_id: queueInfo.branch_id,
          branch_name: branchName,
        };
        dispatch(updateDineinQueueCustomerInfo(queueInformation));
        setStartEditing(false);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getDineInBranchInfo(restaurant_id)
      .then((res) => {
        dispatch(setDineinBranchesInfo(res?.data));
        setBranchInfo(res?.data);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (userQueues.length) {
      setIsUserInQueue(true);
    } else {
      setIsUserInQueue(false);
    }
  }, [userQueues]);
  
  useEffect(()=>{
    // dispatch(resetQueue());
    if (_.isEmpty(userQueueInfo) && isLoggedIn) {
     getUserQueueInfo(restaurant_id)
       .then((res) => {
         if (res.data.length !== 0) {
           const queueInfo = {
             ...res?.data[0]
           };
           dispatch(setDineinQueueCustomerInfo(queueInfo));
         }
       })
       .catch((e) => console.error(e));
   }
 },[]);

  return (
    <div className="dinein-queue-list">
      <Slider
        {...settings}
        // className={retrunLanguage(i18n) ? "float-left" : ""}
      >
        {dineinBranchInfo?.map((queue, index) => (
          <div className="dinein-slider" key={index}>
            <QueueCard
              queue={queue}
              setDineInConfirmDialog={setDineInConfirmDialog}
              setStartEditing={setStartEditing}
              startEditing={startEditing}
              setCancelDineine={setCancelDineine}
              handleJoinQueue={handleJoinQueue}
              restaurant_id={restaurant_id}
              userQueueInfo={
                userQueueInfo.branch_id === queue.branch_id
                  ? userQueueInfo
                  : null
              }
              isLoggedIn={isLoggedIn}
              isUserInQueue={isUserInQueue}
              handleChangeBranch={handleChangeBranch}
              handleUpdateQueueDetails={handleUpdateQueueDetails}
            />
          </div>
        ))}
      </Slider>

      <DineinCancel
        cancelDineine={cancelDineine}
        setCancelDineine={setCancelDineine}
        handleCancel={handleQueueCancel}
        fromReservation={false}
      />

      {queueInfoConfirm !== null && (
        <DineInConfirm
          dineInConfirmDialog={dineInConfirmDialog}
          handleClose={() => handleCloseQueue()}
          branchName={queueInfoConfirm?.branch_name}
          guestInfo={{
            guestName:
              queueInfoConfirm?.first_name + " " + queueInfoConfirm?.last_name,
            seatingArea: queueInfoConfirm?.sitting_area,
            numberOfPersons: queueInfoConfirm?.guest_allowed,
            phoneNumber: `+${queueInfoConfirm?.mobile_country_code} ${queueInfoConfirm?.mobile_no}`,
          }}
          queueNumber={queueInfoConfirm?.queue_no}
          activeButton={activeButton}
        />
      )}
    </div>
  );
};

export default DineinQueue;
