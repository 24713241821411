import React from "react";

import { Box, Typography } from "@mui/material";

import "./style.css";

const MenuItemCard = ({ item }) => {
  const { name_en, name_ar, choices, options } = item;
  return (
    <Box className="menu-item-container">
      <Box className="menu-item-header">
        <Typography variant="h4">{name_en}</Typography>
        <Typography variant="h6">{`Choice of ${choices}`}</Typography>
      </Box>

      {options?.map((option) => {
        return (
          <Box key={option.id} className="menu-item-options">
            <div className="option-item-image">
              <img src={option.image} alt={option.name_en} />
            </div>

            <div className="d-flex flex-column">
              <Typography variant="h3">{option.name_en}</Typography>
              <Typography variant="h5">{option.ingredient}</Typography>
            </div>
          </Box>
        );
      })}
    </Box>
  );
};

export default MenuItemCard;
