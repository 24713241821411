import React from "react";

import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

const DineinCancel = (props) => {
  const { t } = useTranslation("dialog");

  const { cancelDineine, setCancelDineine, handleCancel, fromReservation } =
    props;

  return (
    <Dialog
      open={cancelDineine}
      onClose={() => {
        setCancelDineine(false);
      }}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      className="cart-dialog"
      PaperProps={{
        sx: {
          overflow: "visible",
          //   margin: retrunLanguage(i18n)
          //     ? "0px 27px 0px 0px"
          //     : "0px 0px 0px 27px",
          width: "327px",
          //   right: retrunLanguage(i18n) ? "-5px" : "unset",

          "@media (max-width: 500px)": {
            width: "375px",
            margin: "0px 0px 0px 0px",
          },

          "@media (max-width: 400px)": {
            width: "90%",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-title title-cart">
        <img src="/assets/img/exclaim.svg"></img>
      </DialogTitle>

      <DialogContent className="dialog-content-cart">
        {fromReservation ? t("wantToCancelReservation") : t("wantToCancel")}
      </DialogContent>

      <div className="queue-dialog-vtn-container">
        {fromReservation ? (
          <div>
            <Button
              className="common-st add-btn"
              onClick={() => setCancelDineine(false)}
            >
              {t("goBack")}
            </Button>

            <Button className="common-st go-to-btn" onClick={handleCancel}>
              {t("confirm", { ns: "common" })}
            </Button>
          </div>
        ) : (
          <>
            <Button
              className="queue-cancel-btn"
              onClick={() => handleCancel(setCancelDineine)}
            >
              {t("cancel")}
            </Button>

            <Button
              className="queue-edit-btn"
              onClick={() => {
                setCancelDineine(false);
              }}
            >
              {t("goBack")}
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default DineinCancel;
