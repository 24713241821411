import React from "react";

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";

const QuickOverview = () => {
  return (
    <Box className="quick-overview">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h2">Quick Overview</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography>
            <span>Serves:</span> 8 - 10 peoples
          </Typography>
          <Typography>
            <span>Presentation:</span> Chafing Dishes, Wooden bowls & cake
            stands*, without plates & cutlery.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default QuickOverview;
