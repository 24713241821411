import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";

import { paths } from "../../routes/paths";

import CountryPicker from "../countryPicker";

import { languageSelector, retrunLanguage } from "../../utils/languageSelect";

import "./style.css";

export const NavbarVariants = {
  backWithProfile: "backWithProfile",
};

const Navbar = (props) => {
  const {
    profile,
    logo,
    languageIcon,
    profileIconUrl,
    showFlagOption,
    cartIcon,
    searchIcon,
    backIcon,
    borderedIcon,
    path,
    guestUser,
    backIconURL,
    setOpenAddressPopup,
    title,
    backIconURLState,
    fromHome,
    location,
    autoRedirect,
    register,
    locationNavbar,
    edit,
    addressForm,
    toLabelArabic,
    toLabelEnglish,
    setPickupFromDialog,
  } = props;
  const { i18n, t } = useTranslation("translation");

  const navigate = useNavigate();

  const searchContainerRef = useRef(null);

  const token = localStorage.getItem("token");
  const selectedAddress = localStorage.getItem("selected");
  const currentLocation = JSON.parse(localStorage.getItem("location"));
  const isAfterNewLocation = JSON.parse(
    sessionStorage?.getItem("storeAddress")
  );

  const cart = useSelector((state) => state.cart.cart);
  const addressData = useSelector((state) => state.address.addressData);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const typeStatus = useSelector((state) => state.resturants.typeStatus);
  const allBranches = useSelector((state) => state.resturants.allBranches);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // const toggleSearch = () => {
  //   setIsSearchOpen(!isSearchOpen);
  // };

  const closeSearch = (e) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(e.target)
    ) {
      setIsSearchOpen(false);
    }
  };

  const handleBackButtonClick = () => {
    if (location?.fromBranch !== undefined) {
      return navigate(-1);
    }

    if (token === null) {
      handleGuestNavigation();
    } else {
      handleAuthenticatedNavigation();
    }
  };

  const handleGuestNavigation = () => {
    if (guestUser || location?.guest !== undefined) {
      if (path.includes("/menu/restaurant") || path?.includes("menu-item-details")) {
        return navigate(-1);
      } else {
        return navigate(paths.home);
      }
    }

    if (register !== undefined || backIconURL === -1) {
      return navigate(-1);
    }

    if (autoRedirect !== undefined) {
      if (path.includes("/menu/restaurant")) {
        return navigate(-1);
      } else {
        return navigate(
          `${paths.menu}/restaurant/${+location?.restaurant_id}/delivery`
        );
      }
    }

    if (path?.includes("menu-item-details")) {
      return navigate(-1);
    }

    if (location?.filterSelectedNow) {
      return navigate(paths.home);
    }
  };

  const handleAuthenticatedNavigation = () => {
    if (path === "/profile") {
      return navigate(paths.addresses, { state: { path } });
    }

    if (location?.addNew !== undefined) {
      return navigate(paths.home);
    }

    if (isMenuPath() && !location?.backCart && !location?.editLocation) {
      if (location?.noAddress !== undefined) {
        return navigate(-1);
      } else {
        return navigate(
          `${paths.menu}/restaurant/${+location?.restaurant_id}/delivery`
        );
      }
    }

    if (shouldNavigateToAddresses()) {
      return navigate(paths.addresses, { state: passState });
    }

    if (locationNavbar) {
      return navigate(-1, { state: { locationNavbar } });
    }

    if (location?.notSelected !== undefined) {
      return navigate(-1);
    }

    handleOtherAuthenticatedNavigation();
  };

  const isMenuPath = () =>
    path === "menu/restaurant" || path?.includes("menu-item-details");

  const shouldNavigateToAddresses = () =>
    (location?.fromMenu !== undefined && location?.notSelected === undefined) ||
    location?.fromDetail !== undefined ||
    location?.fromHome !== undefined;

  const handleOtherAuthenticatedNavigation = () => {
    if (edit !== undefined) {
      return navigate(paths.addresses);
    }

    if (backIconURL !== "/cart" && location?.fromMenu !== undefined) {
      return navigate(paths.profile, backIconURLState);
    }

    return navigate(backIconURL, backIconURLState);
  };

  useEffect(() => {
    document.addEventListener("click", closeSearch);

    return () => {
      document.removeEventListener("click", closeSearch);
    };
  }, []);

  const passState = {
    fromMenu: location?.fromMenu,
    fromBranch: location?.fromBranch,
    fromDetail: location?.fromDetail,
    fromHome: location?.fromHome,
    backCart: location?.backCart,
    detailPath: location?.detailPath,
    path: path,
    editLocation: location?.editLocation,
  };

  const stateNoAddress = {
    noAddress: true,
    latlng: {
      lat: currentLocation?.lat,
      lng: currentLocation?.lng,
    },
    name: currentLocation?.address,
    path: "/home",
    fromHome,
    restaurant_id: cart[0]?.restaurant_id,
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const returnBranchName = (brName) => {
    const branch = allBranches
      ?.filter((branch) => branch?.restaurant_id === cart[0]?.restaurant_id)
      ?.some((branch) =>
        retrunLanguage(i18n)
          ? branch.name_ar === brName
          : branch.name_en === brName
      );

    return branch;
  };

  return (
    <nav className={"d-flex align-items-center justify-content-between"}>
      {/* Profile Icon */}
      {profile && (
        <div
          className="iconaccount-btn"
          style={{ border: `${borderedIcon ? "1px solid #c0cdd7" : ""}` }}
          onClick={() => navigate(profileIconUrl || paths.home)}
        >
          {borderedIcon ? (
            <img
              src="/assets/img/Group 506.png"
              alt="Account Icon"
              style={{ width: "21.47px", height: "20px" }}
            />
          ) : (
            <img src="/assets/img/account.svg" alt="Account Icon" />
          )}
        </div>
      )}

      {/* back icon */}
      {backIcon && (
        <div className="d-flex align-items-center">
          <div className="arrowicon-btn" onClick={handleBackButtonClick}>
            <img
              src="/assets/img/Icon - Arrow.svg"
              alt="go back"
              className={retrunLanguage(i18n) ? "rotate" : ""}
            />
          </div>
        </div>
      )}

      {/* Logo  */}
      {logo && (
        <div
          className={`${
            (showFlagOption && languageIcon) || (cartIcon && searchIcon)
              ? `logo-home ${retrunLanguage(i18n) ? "remove-margin" : ""}`
              : ""
          }`}
        >
          <span>
            <img
              style={{ width: "90px" }}
              src="/assets/img/order seazen logo.svg"
              alt="Logo"
            />
            <sub className="beta">{t("header.beta")}</sub>
          </span>
        </div>
      )}
      {title && (
        <Typography
          variant="h5"
          component="h2"
          fontWeight={700}
          sx={{
            mb: 0,
            // ml: "-20px",
            ml: `${!languageIcon ? "-40px" : "0px"}`,
            color: "#1f2933",
          }}
        >
          {title}
        </Typography>
      )}

      {/* search and cart icons  */}
      <div
        className="d-flex align-items-center"
        style={{
          columnGap: "10px",
          marginRight: "-4px",
        }}
      >
        {/* half profile icon */}
        {showFlagOption && (
          <div className="icon-btn-country">
            <CountryPicker />
          </div>
        )}
        {languageIcon && (
          <div
            className={`${
              retrunLanguage(i18n) ? "englishicon-btn" : "arabicicon-btn"
            } common-lng-btn`}
            // style={{ border: `${borderedIcon ? "1px solid #c0cdd7" : ""}` }}
            onClick={() => {
              languageSelector(i18n);

              if (addressForm) {
                if (i18n.language === "en") {
                  toLabelArabic();
                } else if (i18n.language === "ar") {
                  toLabelEnglish();
                }
              }
            }}
          >
            <img
              src={
                i18n.language === "ar"
                  ? "/assets/img/english.svg"
                  : "/assets/img/arabic.svg"
              }
              alt="Option Icon"
            />
          </div>
        )}
        {cartIcon && (
          <div
            className="icon-btn"
            onClick={() => {
              token === null
                ? navigate(paths.cart)
                : isAfterNewLocation !== null &&
                  isAfterNewLocation?.addNewAddress === true &&
                  selectedAddress === null &&
                  cart.length > 0 &&
                  typeStatus &&
                  typeStatus === "delivery"
                ? navigate(paths.address, {
                    state: {
                      latlng: {
                        lat: currentLocation?.lat,
                        lng: currentLocation?.lng,
                      },
                      name: currentLocation?.address,
                      path: "menu/restaurant",
                      restaurant_id: cart[0]?.restaurant_id,
                    },
                  })
                : addressData?.length === 0 &&
                  cart.length &&
                  selectedAddress === null &&
                  typeStatus &&
                  typeStatus === "delivery"
                ? navigate(paths.location, {
                    state: stateNoAddress,
                  })
                : addressData?.length > 0 &&
                  selectedAddress === null &&
                  cart.length > 0 &&
                  typeStatus &&
                  typeStatus === "delivery"
                ? setOpenAddressPopup(true)
                : cart?.length > 0 &&
                  typeStatus &&
                  typeStatus === "pickup" &&
                  (localStorage.getItem("branchName") === null ||
                    returnBranchName(localStorage.getItem("branchName")) ===
                      false)
                ? setPickupFromDialog(true)
                : navigate(paths.cart, {
                    state: { fromHome: fromHome, path: "/home" },
                  });
            }}
          >
            <img src="/assets/img/cart.svg" alt="Shopping Icon" />
            {totalQuantity > 0 && (
              <div className="items-count">{totalQuantity}</div>
            )}
          </div>
        )}

        {searchIcon && (
          <div className="icon-btn">
            <img
              src="/assets/img/Icon - Search.svg"
              alt="search icon"
              onClick={() => {
                navigate(paths.search);
              }}
            />
          </div>
        )}
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  variant: null,
};

export default Navbar;
