import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { retrunLanguage } from "../../utils/languageSelect";
import { useTranslation } from "react-i18next";

export const DineInConfirm = (props) => {
  const {
    dineInConfirmDialog,
    handleClose,
    branchName,
    guestInfo,
    queueNumber,
    activeButton,
    isEdit,
  } = props;

  const { t, i18n } = useTranslation(["dinein", "common"]);

  return (
    <Dialog
      open={dineInConfirmDialog}
      onClose={() => handleClose()}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: "0",
          height: activeButton === "queue" ? "457px" : "577px",
          bottom: "0",
          position: "fixed",
          width: "375px",
          left: retrunLanguage(i18n) ? "unset" : "0",
          right: retrunLanguage(i18n) ? "0" : "unset",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          paddingTop: "15px",
          paddingBottom: "19px",

          "@media (max-width: 500px)": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle className="close-icon" onClick={() => handleClose()}>
        <img src="/assets/img/close.svg" />
      </DialogTitle>

      <DialogContent>
        <div className="d-flex justify-content-center">
          <div className="dineIn-restaurant-logo-dialog">
            <img src="/assets/img/dineinConfirmLogo.svg" />
          </div>
        </div>

        <p className="dineIn-from-common" style={{ margin: "10px 0px 14px" }}>
          {activeButton === "queue"
            ? `${t("thankyouQueue")} ${" "}`
            : isEdit
            ? t("updateReservation")
            : t("thankyouReservation")}
          {activeButton === "queue" && (
            <span
              style={{
                color: "white",
                backgroundColor: "#036855",
                borderRadius: "10px",
                padding: "0px 6px 0px 8px",
              }}
            >
              {queueNumber}
            </span>
          )}
        </p>

        {activeButton === "reservation" && (
          <p className="reservation-description">
            {`${t("recievedRequest")}
    ${t("requestId")} ${guestInfo?.reservationNo}.
    ${t("contactSoon")}`}
          </p>
        )}

        <Box sx={{ px: 2, border: "1px solid #BFCCD7", borderRadius: "10px" }}>
          <p
            className="dineIn-from-common"
            style={{ borderBottom: "1px solid #BFCCD7", padding: "10px" }}
          >
            {t("guestInfo")}
          </p>
          <Grid
            container
            rowSpacing={0}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="dineIn-grid"
          >
            <Grid item xs={6}>
              <p className="dineIn-grid-titles" style={{ margin: "-5px 0" }}>
                {t("guestName")}
              </p>
              <p className="dineIn-from-common">{guestInfo?.guestName}</p>
            </Grid>

            <Grid item xs={6}>
              <p className="dineIn-grid-titles" style={{ margin: "-5px 0" }}>
                {t("branch")}
              </p>
              <p className="dineIn-from-common">{branchName}</p>
            </Grid>

            <Grid item xs={6}>
              <p className="dineIn-grid-titles" style={{ margin: "-5px 0" }}>
                {t("seatingArea")}
              </p>
              <p className="dineIn-from-common">{guestInfo?.seatingArea}</p>
            </Grid>

            <Grid item xs={6}>
              <p className="dineIn-grid-titles" style={{ margin: "-5px 0" }}>
                {t("numberOfPersons")}
              </p>
              <p className="dineIn-from-common">
                {guestInfo?.numberOfPersons?.toString().padStart(2, "0")}
              </p>
            </Grid>

            {activeButton === "reservation" && (
              <>
                <Grid item xs={6}>
                  <p
                    className="dineIn-grid-titles"
                    style={{ margin: "-5px 0" }}
                  >
                    {t("reservationDate")}
                  </p>
                  <p className="dineIn-from-common">
                    {guestInfo?.reservationDate}
                  </p>
                </Grid>

                <Grid item xs={6}>
                  <p
                    className="dineIn-grid-titles"
                    style={{ margin: "-5px 0" }}
                  >
                    {t("reservationTime")}
                  </p>
                  <p className="dineIn-from-common">
                    {guestInfo?.reservationTime}
                  </p>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <p className="dineIn-grid-titles" style={{ margin: "-5px 0" }}>
                {t("phoneNo")}
              </p>
              <p className="dineIn-from-common">{guestInfo?.phoneNumber}</p>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
