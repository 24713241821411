import { useEffect, useState } from 'react';
import branchQueueStatus from './getBranchQueueStatus'; // Ensure this path is correct

const usePollingQueueStatus = (branchId, restaurantId, interval = 30000) => {
  const [queueData, setQueueData] = useState(null);
 console.log(queueData,"shahqueueData");
  // Memoized function to fetch queue status
  const fetchQueueStatus = async () => {
    try {
      const data = await branchQueueStatus(branchId, restaurantId);
      setQueueData(data);
    } catch (error) {
      console.error("Failed to fetch queue status:", error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchQueueStatus();

    // Set up polling
    const polling = setInterval(fetchQueueStatus, interval);

    // Cleanup interval on component unmount or dependencies change
    return () => clearInterval(polling);
  }, [interval]);

  return queueData;
};

export default usePollingQueueStatus;
