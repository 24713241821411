import React from "react";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DynamicExclaim from "./dynamicExclaim";
import { useTranslation } from "react-i18next";
import { retrunLanguage } from "../../../../utils/languageSelect";
import { setIsEditReservation } from "../../../../store/reducers/resturantsSlice";

function QueueAndReserveInfo({ restaurant_id, isQueue }) {
  const { t, i18n } = useTranslation("dinein");
  const dispatch = useDispatch();

  const userQueues = useSelector(
    (state) => state?.resturants?.dineIn?.userQueueInfo
  );
  const userReservations = useSelector(
    (state) => state?.resturants?.dineIn?.userReservations
  );
  const userReservationInfo =
    userReservations?.find(
      (element) => element.restaurant_id === restaurant_id
    ) || {};
  const userQueueInfo =
    userQueues?.find((element) => element.restaurant_id === restaurant_id) ||
    {};
  const branches = useSelector((state) => state?.resturants?.branchHours);

  const handleEdit = () => {
    dispatch(setIsEditReservation(true));
  };

  return (
    <div>
      {isQueue
        ? !isEmpty(userQueueInfo) && (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ px: 3, py: 1, backgroundColor: "#F0F6F5" }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ margin: "-3px 5px 0px 0px" }}>
                  <DynamicExclaim fillColor={"#000000"} />
                </div>
                <div>
                  <p
                    style={{
                      color: "#000000",
                      fontSize: "12px",
                      margin: "0px 5px 0px 0px",
                    }}
                  >
                    {" "}
                    {`${t("currentQueueAt")}`}
                    <br />
                    {`${userQueueInfo?.branch_name}, ${
                      userQueueInfo?.guest_allowed
                    } ${t("persons")}, ${userQueueInfo?.sitting_area}`}
                  </p>
                </div>
              </div>
              <span
                style={{
                  color: "white",
                  height: "35px",
                  width: "35px",
                  backgroundColor: "#036855",
                  borderRadius: "10px",
                  paddingTop: "5px",
                  textAlign: "center",
                }}
              >
                {userQueueInfo?.queue_no}
              </span>
            </Box>
          )
        : !isEmpty(userReservationInfo) && (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              position={"relative"}
              alignItems={"center"}
              sx={{ px: 3, py: 1, backgroundColor: "#F0F6F5" }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ margin: "-3px 5px 0px 0px" }}>
                  <DynamicExclaim fillColor={"#000000"} />
                </div>
                <div>
                  <p
                    style={{
                      color: "#000000",
                      fontSize: "12px",
                      margin: "0px 5px 0px 0px",
                    }}
                  >
                    {" "}
                    {`${t("currentReservationAt")}`}
                    <br />
                    {`${
                      branches.find(
                        (branch) => branch.id === userReservationInfo?.branch_id
                      )?.[retrunLanguage(i18n) ? "name_ar" : "name_en"]
                    }, ${userReservationInfo?.guest_allowed} ${t("persons")}, ${
                      userReservationInfo?.sitting_area
                    }, ${userReservationInfo?.reservation_date}, ${
                      userReservationInfo?.reservation_time
                    }`}
                  </p>
                </div>
              </div>
              <span
                style={{
                  position: "absolute",
                  right: "50px",
                  top: "10px",
                  color: "black",
                  height: "10.5px",
                  width: "10.5px",
                  font: "normal normal normal 12px Titillium Web",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "underline",
                  cursor: "pointer ",
                }}
                onClick={handleEdit}
              >
                {t("edit", { ns: "common" })}{" "}
                <img
                  src={"/assets/dinein/editpencil.svg"}
                  alt="edit"
                  style={{ marginLeft: "3px" }}
                ></img>
              </span>
            </Box>
          )}
    </div>
  );
}

export default QueueAndReserveInfo;
