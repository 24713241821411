import axiosInstance from "../../axiosInstance";
import API_ENDPOINTS from "../../endpoints";
import { handleApiError } from "../../utils/errorHandling";

const joinQueue = async (queueInfo) => {
  try {
    const payload = {
      guest_allowed: queueInfo?.guests,
      sitting_area: queueInfo?.seatingArea,
      first_name: queueInfo?.firstName,
      last_name: queueInfo?.lastName,
      mobile_no: queueInfo?.mobile_no,
      mobile_country_code: queueInfo?.mobile_country_code,
      branch_id: queueInfo?.branch_id,
      restaurant_id:queueInfo?.restaurantId
    };
    // Make the POST request to get all delivery price.
    const { data } = await axiosInstance.post(
      `${API_ENDPOINTS.RESTAURANT.DINEIN.QUEUE_REQUEST}/${queueInfo?.branch_id}`,
      payload   
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default joinQueue;
