import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import { useTranslation } from "react-i18next";

import ReserveForm from "./reserveForm";
import BranchesCards from "./branches";
import DatePickerDialog from "../../../dialog/datePickerDialog";
import { DineInConfirm } from "../../../dialog/dineInConfirm";
import ExistingReservation from "../../../dialog/existingReservation";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import createReservation from "../../../../api/restaurant/dineIn/createReservation";
import {
  setDineinReservationInfo,
  setIsEditReservation,
  setUserReservations,
  updateReservationInfo,
} from "../../../../store/reducers/resturantsSlice";
import { paths } from "../../../../routes/paths";
import { retrunLanguage } from "../../../../utils/languageSelect";
import getReservations from "../../../../api/restaurant/dineIn/getReservations";
import editReservation from "../../../../api/restaurant/dineIn/editReservation";
import { convertTo24Hour } from "../../../../utils/helpers";

import "./style.css";

const DineinReservation = ({ activeButton, restaurant_id }) => {
  const { t, i18n } = useTranslation("dinein");
  const token = localStorage?.getItem("token");
  const isLoggedIn = token !== null;
  const { userReservations, existingReservationInfo, isEditReservation } =
    useSelector((state) => state?.resturants?.dineIn);
  const branches = useSelector((state) => state?.resturants?.branchHours);

  const [dineinDateTimeSelector, setDineinDateTimeSelector] = useState(false);
  const [isPickerType, setIsPickerType] = useState("date");
  const [reservationInfoConfrim, setReservationInfoConfirm] = useState();
  const [errorReservationDialog, setErrorReservationDialog] = useState(false);
  const [dineInConfirmDialog, setDineInConfirmDialog] = useState(false);
  const [isEdit, setEdit] = useState(isEditReservation);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setDineinDateTimeSelector(false);
    setIsPickerType("date");
  };

  const fetchReservations = useCallback(async () => {
    try {
      const res = await getReservations(); // Using your actual fetch function
      if (res?.data && res.data.length > 0) {
        dispatch(setUserReservations(res.data));
      }
    } catch (error) {
      console.error("Failed to fetch reservations:", error);
    }
  }, [dispatch]); // Include dispatch here to adhere to best practices

  const handleCancelEdit = () => {
    if (isEdit) {
      setEdit(false);
      dispatch(setIsEditReservation(false));
    }
  };

  const handleUpdateReservation = (reservationInfo) => {
    editReservation(reservationInfo, restaurant_id)
      .then((res) => {
        const reservationDetails = {
          ...res.data,
        };
        setReservationInfoConfirm(reservationDetails);
        dispatch(updateReservationInfo(reservationDetails));
        setDineInConfirmDialog(true);
      })
      .catch((e) => console.error(e));
  };

  const checkValidReservation = ({
    reservationDate,
    reservationTime,
    branchId,
  }) => {
    // Convert provided reservation date and time into a Date object
    const newReservationDateTime = convertTo24Hour(
      reservationDate,
      reservationTime
    );
    return userReservations.some((item) => {
      if (
        item.reservation_date === reservationDate &&
        item.branch_id == branchId &&
        item.restaurant_id == restaurant_id
      ) {
        // Parse existing reservation date and time into a Date object
        const existingReservationDateTime = convertTo24Hour(
          item.reservation_date,
          item.reservation_time
        );

        // Calculate the time difference in hours
        const timeDifference =
          Math.abs(newReservationDateTime - existingReservationDateTime) /
          (1000 * 60 * 60); // in hours

        // Return true if the times are the same, less than 2 hours apart, or more than 2 hours apart
        return timeDifference < 2;
      }
      return false;
    });
  };

  const handleReservation = (reservationInfo) => {
    const sharedState = {
      restaurant_id: +restaurant_id,
      path: location.pathname,
      guest: true,
      filterSelectedNow: "dinein",
    };
    if (isLoggedIn) {
      const hasExistingReservation = checkValidReservation(reservationInfo);

      if (!hasExistingReservation) {
        createReservation(reservationInfo, restaurant_id)
          .then((res) => {
            const reservationDetails = {
              ...res.data,
            };
            setReservationInfoConfirm(reservationDetails);
            dispatch(setDineinReservationInfo(reservationDetails));
            setDineInConfirmDialog(true);
          })
          .catch((e) => console.error(e));
      } else {
        setErrorReservationDialog(true);
      }
    } else {
      dispatch(setDineinReservationInfo(reservationInfo));
      navigate(paths.phone_verification_index, {
        state: sharedState,
      });
    }
  };

  useEffect(() => {
    if (!userReservations || userReservations.length === 0) {
      fetchReservations(); // Fetch data if needed
    }
  }, [userReservations, fetchReservations]); // Use fetchReservations here

  useEffect(() => {
    setEdit(isEditReservation);
  }, [isEditReservation]);

  return (
    <Box className="reserve-container">
      <ReserveForm
        existingReservationInfo={isEdit ? existingReservationInfo : null}
        setDineinDateTimeSelector={setDineinDateTimeSelector}
        setIsPickerType={setIsPickerType}
        setDate={setDate}
        setTime={setTime}
        date={date}
        time={time}
        restaurant_id={restaurant_id}
        handleReservation={handleReservation}
        isLoggedIn={isLoggedIn}
        branches={branches}
        isEdit={isEdit}
        handleCancelEdit={handleCancelEdit}
        handleUpdateReservation={handleUpdateReservation}
      />
      <div
        style={{
          marginTop: "11px",
        }}
      >
        {/* <BranchesCards /> */}
      </div>
      <DatePickerDialog
        dineinDateTimeSelector={dineinDateTimeSelector}
        handleClose={handleClose}
        isPickerType={isPickerType}
        setIsPickerType={setIsPickerType}
        setDineinDateTimeSelector={setDineinDateTimeSelector}
        setDate={setDate}
        setTime={setTime}
      />
      <DineInConfirm
        dineInConfirmDialog={dineInConfirmDialog}
        setEdit={setEdit}
        isEdit={isEdit}
        handleClose={() => {
          setDineInConfirmDialog(false);
          handleCancelEdit();
        }}
        branchName={
          branches.find(
            (branch) => branch.id === reservationInfoConfrim?.branch_id
          )?.[retrunLanguage(i18n) ? "name_ar" : "name_en"]
        }
        guestInfo={{
          guestName:
            reservationInfoConfrim?.first_name +
            " " +
            reservationInfoConfrim?.last_name,
          seatingArea: reservationInfoConfrim?.sitting_area,
          numberOfPersons: reservationInfoConfrim?.guest_allowed,
          reservationDate: reservationInfoConfrim?.reservation_date,
          reservationTime: reservationInfoConfrim?.reservation_time,
          phoneNumber: `+${reservationInfoConfrim?.mobile_country_code} ${reservationInfoConfrim?.mobile_no}`,
          reservationNo: reservationInfoConfrim?.reservation_no,
        }}
        queueNumber={reservationInfoConfrim?.queue_no}
        activeButton={activeButton}
      />
      <ExistingReservation
        open={errorReservationDialog}
        setOpen={setErrorReservationDialog}
      />
    </Box>
  );
};

export default DineinReservation;
