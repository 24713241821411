import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import { Dialog, DialogContent } from "@mui/material";

import ReserveForm from "../restaurant/dineIn/dineinReservation/reserveForm";
import DatePickerDialog from "./datePickerDialog";

import { retrunLanguage } from "../../utils/languageSelect";

const ReserveTable = (props) => {
  const { t, i18n } = useTranslation("dialog");

  const { open, setOpen } = props;

  const [dineinDateTimeSelector, setDineinDateTimeSelector] = useState(false);
  const [isPickerType, setIsPickerType] = useState("date");

  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const handleClose = () => {
    setDineinDateTimeSelector(false);
    setIsPickerType("date");
  };

  const formatDate = () => {
    if (!date) return "";
    return dayjs(date).format("DD MMM YY");
  };

  const formatTime = () => {
    if (!time) return "";
    return dayjs(time).format("h:mm A").toLowerCase();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      className="cart-dialog"
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: retrunLanguage(i18n)
            ? "0px 27px 0px 0px"
            : "0px 0px 0px 27px",
          width: "327px",
          right: retrunLanguage(i18n) ? "-5px" : "unset",

          "@media (max-width: 500px)": {
            width: "375px",
            margin: "0px 0px 0px 0px",
          },

          "@media (max-width: 400px)": {
            width: "90%",
          },
        },
      }}
    >
      <DialogContent
        className="dialog-content-cart"
        sx={{ padding: "21px 12px 4px !important" }}
      >
        <ReserveForm
          setDineinDateTimeSelector={setDineinDateTimeSelector}
          setIsPickerType={setIsPickerType}
          setDate={setDate}
          setTime={setTime}
          formatDate={formatDate}
          formatTime={formatTime}
          isModal={true}
          setOpen={setOpen}
        />

        <DatePickerDialog
          dineinDateTimeSelector={dineinDateTimeSelector}
          handleClose={handleClose}
          isPickerType={isPickerType}
          setIsPickerType={setIsPickerType}
          setDineinDateTimeSelector={setDineinDateTimeSelector}
          setDate={setDate}
          setTime={setTime}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ReserveTable;
