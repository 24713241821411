import React from "react";

import { Box, Typography } from "@mui/material";

const PriceBar = ({ isArabic, t, price }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: -2.5 * 8,
        right: isArabic ? "unset" : 3 * 8,
        left: isArabic ? 3 * 8 : "unset",
        backgroundColor: "#026754",
        color: "white",
        borderRadius: "12px",
        zIndex: 1,
        width: "121px",
        height: "35px",
        display: "flex",
        justifyContent: "center",
        paddingTop: "8px",
      }}
    >
      <Typography
        variant="body1"
        component="div"
        className={`${isArabic ? "price-box" : ""}`}
      >
        <Typography
          variant="subtitle2"
          component="sup"
          className="curr"
          sx={{
            mr: 0.5,
            fontWeight: "600",
            fontSize: "13px",
          }}
        >
          {t("kwd", { ns: "common" })}
        </Typography>
        <div
          style={{
            fontWeight: "700",
            marginTop: isArabic ? "-4px" : "-29px",
            marginLeft: "29px",
            fontSize: "18px",
          }}
          className="price-div"
        >
          {price.toFixed(3)}
        </div>
      </Typography>
    </Box>
  );
};

export default PriceBar;
