import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Box, Stack, Typography } from "@mui/material";

import PriceBar from "../../components/branchItemDetail/priceBar";
import ItemHeader from "../../components/branchItemDetail/itemHeader";
import { DetailsHeader } from "../../components/header/detailsHeader";
import QuickOverview from "../../components/branchItemDetail/quickOverview";
import AdditionalInformation from "../../components/branchItemDetail/additionalInformation";

import { retrunLanguage } from "../../utils/languageSelect";

import "./style.css";
import ReserveTable from "../../components/dialog/reserveTable";

const allMenus = [
  {
    id: 1,
    name_en: "Set Menu 1",
    name_ar: "Set Menu 1",
  },
  {
    id: 2,
    name_en: "Set Menu 2",
    name_ar: "Set Menu 2",
  },
  {
    id: 3,
    name_en: "Set Menu 3",
    name_ar: "Set Menu 3",
  },
  {
    id: 4,
    name_en: "Set Menu 4",
    name_ar: "Set Menu 4",
  },
  {
    id: 5,
    name_en: "Set Menu 5",
    name_ar: "Set Menu 5",
  },
];

const menuItems = [
  {
    id: 1,
    name_en: "Salad",
    name_ar: "Salad",
    choices: 1,
    options: [
      {
        id: 1,
        name_en: "Kiki Salad",
        name_ar: "Kiki Salad",
        ingredient:
          "Mesclun Leaves, Shrimps & Truffle Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Kiki Salad.png",
      },
      {
        id: 2,
        name_en: "Ceaser Salad",
        name_ar: "Ceaser Salad",
        ingredient:
          "Croutons, Garlic Dressing & Parmesan Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Ceaser Salad.png",
      },
      {
        id: 3,
        name_en: "Capra Salad",
        name_ar: "Capra Salad",
        ingredient:
          "Goat cheese, cashew & Balsamic vinegar Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Capra Salad.png",
      },
    ],
  },
  {
    id: 2,
    name_en: "Apetizers",
    name_ar: "Apetizers",
    choices: 1,
    options: [
      {
        id: 1,
        name_en: "Pollo Pazzo",
        name_ar: "Pollo Pazzo",
        ingredient:
          "Mesclun Leaves, Shrimps & Truffle Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Pollo Pazzo.png",
      },
      {
        id: 2,
        name_en: "Arancino Palla",
        name_ar: "Arancino Palla",
        ingredient:
          "Croutons, Garlic Dressing & Parmesan Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Arancino Palla.png",
      },
      {
        id: 3,
        name_en: "Bianca Pasta",
        name_ar: "Bianca Pasta",
        ingredient:
          "Goat cheese, cashew & Balsamic vinegar Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Bianca Pasta.png",
      },
    ],
  },
  {
    id: 3,
    name_en: "Pasta or Risotto",
    name_ar: "Pasta or Risotto",
    choices: 1,
    options: [
      {
        id: 1,
        name_en: "Pollo Pazzo",
        name_ar: "Pollo Pazzo",
        ingredient:
          "Mesclun Leaves, Shrimps & Truffle Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Pollo Pazzo Pasta.png",
      },
      {
        id: 2,
        name_en: "Arancino Palla",
        name_ar: "Arancino Palla",
        ingredient:
          "Croutons, Garlic Dressing & Parmesan Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Arancino Palla Pasta.png",
      },
      {
        id: 3,
        name_en: "Bianca Pasta",
        name_ar: "Bianca Pasta",
        ingredient:
          "Goat cheese, cashew & Balsamic vinegar Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Bianca Pasta Risotto.png",
      },
    ],
  },
  {
    id: 4,
    name_en: "Desserts",
    name_ar: "Desserts",
    choices: 2,
    options: [
      {
        id: 1,
        name_en: "Cinnamon buns",
        name_ar: "Cinnamon buns",
        ingredient:
          "Mesclun Leaves, Shrimps & Truffle Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Cinnamon buns.png",
      },
      {
        id: 2,
        name_en: "Zenzero",
        name_ar: "Zenzero",
        ingredient:
          "Croutons, Garlic Dressing & Parmesan Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Zenzero.png",
      },
      {
        id: 3,
        name_en: "Garlic bread",
        name_ar: "Garlic bread",
        ingredient:
          "Goat cheese, cashew & Balsamic vinegar Shrimps & Truffle, Mesclun Leaves",
        image: "/assets/dinein/Garlic bread.png",
      },
    ],
  },
];

const BranchItemDetail = () => {
  const { i18n, t } = useTranslation("translation");

  const isArabic = retrunLanguage(i18n);

  const [open, setOpen] = useState(false);

  return (
    <>
      <DetailsHeader image={"/assets/dinein/Image.png"} prevPage={true} />

      <div className="container-fluid section p-0">
        <Stack
          spacing={2}
          sx={{
            color: "common.black",
            paddingTop: "12px",
          }}
        >
          <Box
            sx={{
              padding: "12px 15px 0px",
            }}
          >
            <PriceBar isArabic={isArabic} t={t} price={45.0} />

            <ItemHeader
              itemName={"Happy Anniversary"}
              totalPerson={2}
              description={
                "Appertizers, pasta, risotto, main dishes & desserts"
              }
            />
          </Box>

          <QuickOverview />

          <Box className="outdoor-seating">
            <span>
              <img src="/assets/dinein/outdoor.svg" />
            </span>

            <Typography>This property have outdoor seating</Typography>
          </Box>

          <AdditionalInformation
            allMenus={allMenus}
            menuItems={menuItems}
            setOpen={setOpen}
          />
        </Stack>
      </div>

      <ReserveTable open={open} setOpen={setOpen} />
    </>
  );
};

export default BranchItemDetail;
